<template>
  <v-card
    :class="[{'active': !disabled}, { 'dragover': dragover }, { 'on-error': error }]"
    outlined
    @drop.prevent="onDrop($event)"
    @dragover.prevent="dragover = true"
    @dragenter.prevent="dragover = true"
    @dragleave.prevent="dragover = false"
  >
    <v-card-text>
      <v-row v-if="fileError">
        <v-col>
          <v-alert
            dense
            outlined
            dismissible
            prominent
            type="error"
          >
            <div class="text-h6">
              Erreur(s) de fichier(s) :
            </div>
            <ul>
              <li
                v-for="(error, k) in textError"
                :key="k"
              >
                {{ error }}
              </li>
            </ul>
          </v-alert>
        </v-col>
      </v-row>
      <v-row
        class="d-flex flex-column"
        dense
        align="center"
        justify="center"
        :class="{ disabled }"
      >
        <v-col>
          <v-icon
            :class="[dragover ? 'mt-2, mb-6' : 'mt-5']"
            size="60"
          >
            {{ mdiCloudUpload }}
          </v-icon>
          <p>
            Glissez vos fichiers ici ou <a @click="selectFile">cliquez ici pour envoyer</a>.<br>
            <span class="caption">(Formats jpg, png ou pdf de moins de 16Mo).</span> {{ disabled }}
            <input
              :id="inputId"
              class="el-upload__input"
              type="file"
              name="file"
              multiple=""
              accept="image/png, image/jpeg, application/pdf"
              :disabled="disabled"
              @change="onFileSelected"
            />
          </p>
        </v-col>
      </v-row>
      <v-simple-table
        v-if="uploadedFiles.length > 0"
        dense
      >
        <template v-slot:default>
          <tbody>
            <tr
              v-for="(item, index) in uploadedFiles"
              :key="index"
            >
              <td>
                <div class="d-flex align-center">
                  <v-progress-circular
                    v-if="loading[index].uploading"
                    :size="24"
                    class="mr-2"
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                  <v-icon
                    v-else
                    size="24"
                    class="mr-2"
                    :color="item.validated ? 'success' : 'primary'"
                  >
                    {{ item.validated ? mdiCheckBold : mdiHelpCircle }}
                  </v-icon>
                  <div class="mr-5 text-no-wrap">
                    {{ item.name }}
                  </div>
                  <v-progress-linear
                    v-if="loading[index].uploading"
                    v-model="loading[index].progress"
                    value="15"
                  ></v-progress-linear>
                </div>
              </td>
              <td class="text-right actionColumn">
                <v-btn
                  icon
                  title="Télécharger le fichier"
                  :loading="loading[index].downloading"
                  :disabled="loading[index].uploading"
                  @click="showFile(item, index)"
                >
                  <v-icon> {{ mdiFileDownload }} </v-icon>
                </v-btn>

                <v-btn
                  icon
                  title="Supprimer"
                  :disabled="loading[index].uploading || disabled"
                  @click.stop="confirmDelete(index)"
                >
                  <v-icon> {{ mdiDelete }} </v-icon>
                </v-btn>
              </td>
              <td class="validationColumn">
                <v-switch
                  v-model="item.validated"
                  label="Valide"
                  color="success"
                  :loading="loading[index].validation"
                  :disabled="loading[index].validation || loading[index].uploading || disabled"
                  @click="validateFile(item, index)"
                ></v-switch>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
    <v-snackbar
      v-model="snackbarVisible"
      :timeout="3000"
      :color="snackbarType"
      top
    >
      {{ snackbarMsg }}
    </v-snackbar>
    <v-dialog
      v-model="deleteDialog"
      max-width="460"
    >
      <v-card>
        <v-card-title class="text-h5 text-center d-block">
          Suppression du fichier<br /> "{{ fileToDelete.name }}"
        </v-card-title>
        <v-card-text>Êtes-vous certain de vouloir supprimer ce fichier ? Attention cette action est définitive !</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="mr-2"
            color="secondary"
            outlined
            :disabled="deleteLoading"
            @click="deleteDialog = false"
          >
            Annuler
          </v-btn>
          <v-btn
            color="primary"
            outlined
            :loading="deleteLoading"
            @click="removeFile()"
          >
            Oui, supprimer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import axios from '@axios'
import {
  mdiCheckBold, mdiCloseCircle, mdiCloudUpload, mdiDelete, mdiFileDownload, mdiHelpCircle,
} from '@mdi/js'
import themeConfig from '@themeConfig'
import {
  defineComponent, onMounted, ref, watch,
} from '@vue/composition-api'
import store from '@/store/adhesion'
import fileDownload from '../@core/utils/filesDownload'

export default defineComponent({
  name: 'Upload',
  props: {
    documentType: {
      type: String,
      default: '',
    },
    inputId: {
      type: String,
      default: 'files-field',
    },
    fileTypes: {
      type: Array,
      default: () => ['image/jpeg', 'image/png', 'image/pdf', 'application/pdf'],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    maxSize: {
      type: Number,
      default: 26000000,
    },
    documentId: {
      type: Number,
      default: null,
    },
  },
  setup(props, { emit }) {
    // eslint-disable-next-line no-new-wrappers
    // const textError = new String("Attention, impossible d'ajouter le ou les fichiers car ils ne sont pas au bon format (jpg, png, pdf) ou sont trop lourd (+ 2Mo).")
    const textError = ref([])
    const fileError = ref(false)
    const dragover = ref(false)
    const error = ref(false)
    const loading = ref([])
    const deleteDialog = ref(false)
    const deleteLoading = ref(false)

    const snackbarVisible = ref(false)
    const snackbarMsg = ref('')
    const snackbarType = ref('error')
    const fileToDelete = ref({
      index: null,
      name: '',
    })

    const uploadedFiles = ref([])
    const { fileTypes, documentType } = props

    // const existingFiles = computed({
    //   get() {
    //     return store.state.adherent.documents[documentType]
    //   },
    //   set(val) {
    //     store.commit('adherent/ADD_DOCUMENT', { type: documentType, data: val })
    //   },
    // })

    const formatBytes = (bytes, decimals = 2) => {
      if (bytes === undefined) return ''
      if (bytes === 0) return '0 Bytes'

      const k = 1000 // 1024
      const dm = decimals < 0 ? 0 : decimals
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

      const i = Math.floor(Math.log(bytes) / Math.log(k))

      return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`
    }

    // const checkFile = file => fileTypes.includes(file.type) && file.size < props.maxSize
    const checkFile = file => {
      let success = true
      if (!fileTypes.includes(file.type)) {
        textError.value.push(`Le fichier ${file.name} n'est pas au bon format.`)
        success = false
      }

      if (file.size > props.maxSize) {
        const size = formatBytes(file.size)
        const max = formatBytes(props.maxSize)
        textError.value.push(`Le fichier ${file.name} est trop lourd (${size} pour ${max} max.).`)
        success = false
      }

      return success
    }

    const upload = async () => {
      // eslint-disable-next-line no-restricted-syntax
      for (const [i, file] of uploadedFiles.value.entries()) {
        if (file.constructor.name === 'File') {
          const index = i
          const formData = new FormData()
          formData.append('document', documentType)
          formData.append('adherent_id', store.state.adherent.documents.adherent_id)
          formData.append('file', file)
          // eslint-disable-next-line no-await-in-loop
          await axios
            .post(`documents/${store.state.adherent.documents.id}/add`, formData, {
              timeout: 0,
              headers: {
                'Content-Type': 'multipart/form-data',
              },
              onUploadProgress: progressEvent => {
                const totalLength = progressEvent.loaded / progressEvent.total
                if (totalLength !== null) {
                  loading.value[index].progress = parseInt(totalLength * 100, 10)
                }
              },
            })
            .then(response => {
              loading.value[index].uploading = false
              uploadedFiles.value[index] = response.data

              // store.commit('adherent/ADD_DOCUMENT', { type: documentType, data: response.data })
            })
            .catch(e => {
              textError.value.push(`Impossible de sauvegarder le fichier ${uploadedFiles.value[index].name} : ${e.message}`)
              fileError.value = true
              uploadedFiles.value.splice(index, 1)
            })
            .finally(() => {
              loading.value[index].uploading = false
              emit('update:filesValidated', uploadedFiles.value.length > 0 ? uploadedFiles.value.every(f => f.validated === true) : false)
            })
        }
      }
    }

    const onDrop = e => {
      if (props.disabled) {
        return
      }
      error.value = false
      dragover.value = false
      fileError.value = false
      textError.value = []

      // let fileError = false

      if (e.dataTransfer.items.length) {
        for (let i = 0; i < e.dataTransfer.items.length; i += 1) {
          if (e.dataTransfer.items[i].kind === 'file') {
            const file = e.dataTransfer.items[i].getAsFile()
            if (checkFile(file)) {
              loading.value.push({
                downloading: false, validation: false, uploading: true, progress: 0,
              })
              uploadedFiles.value.push(file)
            } else {
              fileError.value = true
            }
          }
        }
      } else {
        for (let i = 0; i < e.dataTransfer.files.length; i += 1) {
          if (checkFile(e.dataTransfer.files[i])) {
            loading.value.push({
              downloading: false, validation: false, uploading: true, progress: 0,
            })
            uploadedFiles.value.push(e.dataTransfer.files[i])
          } else {
            fileError.value = true
          }
        }
      }

      upload()
    }

    const confirmDelete = index => {
      deleteDialog.value = true
      fileToDelete.value.index = index
      fileToDelete.value.name = uploadedFiles.value[index].name
    }

    const removeFile = async () => {
      deleteLoading.value = true
      const { index } = fileToDelete.value

      // const index = uploadedFiles.value.findIndex(file => file.name === fileName)
      await axios.get(`documents/${store.state.adherent.documents.id}/delete?type=${documentType}&index=${index}`)
        .then(() => {
          snackbarVisible.value = true
          snackbarType.value = 'success'
          snackbarMsg.value = 'Le fichier a bien été supprimé.'
          if (index > -1) uploadedFiles.value.splice(index, 1)
          emit('update:filesValidated', uploadedFiles.value.length > 0 ? uploadedFiles.value.every(f => f.validated === true) : false)
        })
        .catch(() => {
          snackbarVisible.value = true
          snackbarType.value = 'error'
          snackbarMsg.value = 'Une erreur est survenue, impossible de supprimer le fichier.'
        })
        .finally(() => {
          deleteLoading.value = false
          deleteDialog.value = false
          fileToDelete.value.index = null
          fileToDelete.value.name = ''
        })
    }

    const showFile = async (item, index) => {
      if (loading.value[index].downloading) {
        return false
      }
      loading.value[index].downloading = true
      const url = `${themeConfig.app.baseURL}api/documents/${props.documentId}/show?type=${documentType}&index=${index}`

      try {
        await fileDownload(url, item.name)
      } catch (e) {
        snackbarVisible.value = true
        snackbarMsg.value = `Une erreur est survenue : ${e}`
        throw e
      } finally {
        loading.value[index].downloading = false
      }

      return true
    }

    const getFiles = () => uploadedFiles.value
    const removeFiles = () => {
      uploadedFiles.value = []
    }

    const selectFile = () => {
      if (!props.disabled) {
        document.getElementById(props.inputId).click()
      }
    }

    const onFileSelected = () => {
      // let fileError = false
      textError.value = []
      const input = document.getElementById(props.inputId)
      const curFiles = input.files
      if (curFiles.length > 0) {
        /* eslint-disable */
        for (const file of curFiles) {
          if (checkFile(file)) {
            loading.value.push({ downloading: false, validation: false, uploading: true, progress: 0 })
            uploadedFiles.value.push(file)
          } else {
            fileError.value = true
          }
        }
        /* eslint-enable */
      }
      upload()
    }

    const validateFile = async (item, index) => {
      loading.value[index].validation = true
      fileError.value = false
      textError.value = []
      await axios.patch(`documents/${store.state.adherent.documents.id}/validate-file`, { type: documentType, index, validated: item.validated })
        .then(response => {
          uploadedFiles.value[index].validated = response.data.validated
        })
        .catch(e => {
          uploadedFiles.value[index].validated = !item.validated
          textError.value.push(`Une erreur est survenue lors de la validation du fichier : ${e}`)
          fileError.value = true
        })
        .finally(() => {
          loading.value[index].validation = false
          emit('update:filesValidated', uploadedFiles.value.length > 0 ? uploadedFiles.value.every(file => file.validated === true) : false)
        })
    }

    onMounted(() => {
      if (store.state.adherent.documents[documentType] !== undefined) {
        const currentFiles = store.state.adherent.documents[documentType].map((item, i) => {
          // eslint-disable-next-line no-param-reassign
          item.index = i
          loading.value.push({ downloading: false, validation: false, uploading: false })

          return item
        })
        uploadedFiles.value = currentFiles
        emit('update:filesValidated', uploadedFiles.value.length > 0 ? uploadedFiles.value.every(file => file.validated === true) : false)
      }
    })

    // watch(() => filesValidated, val => {
    //   emit('update:filesValidated', val)
    //   console.log('filesValidated', filesValidated)
    // })

    // watch(() => uploadedFiles.value, val => {
    //   console.log(val, val.every(file => file.validated === true))
    //   emit('update:filesValidated', val.every(file => file.validated === true))
    // })

    watch(() => store.state.adherent.documents[documentType], (newValue, oldValue) => {
      if (newValue.length && newValue !== oldValue) {
        const currentFiles = newValue.map((item, i) => {
          // eslint-disable-next-line no-param-reassign
          item.index = i
          loading.value.push({ downloading: false, validation: false, uploading: false })

          return item
        })
        uploadedFiles.value = currentFiles
        emit('update:filesValidated', uploadedFiles.value.every(file => file.validated === true))
      }
    })

    return {
      mdiCloudUpload,
      mdiCloseCircle,
      mdiFileDownload,
      mdiCheckBold,
      mdiHelpCircle,
      mdiDelete,
      dragover,
      uploadedFiles,
      loading,
      onDrop,
      removeFile,
      showFile,
      getFiles,
      removeFiles,
      selectFile,
      onFileSelected,
      formatBytes,
      snackbarVisible,
      snackbarMsg,
      snackbarType,
      error,
      fileError,
      textError,
      validateFile,
      deleteDialog,
      deleteLoading,
      confirmDelete,
      fileToDelete,
    }
  },
})
</script>

<style scoped>
.el-upload__input {
  display: none;
}

.file-separator {
  border-width: 1px;
  border-radius: 0;
}

.active.dragover {
  border-style: dashed;
  border-color: #b7a088;
}

.on-error {
  border-color: red;
}

.validationColumn {
  width: 140px;
}

.actionColumn {
  width: 110px;
}

.disabled {
  opacity: 0.3;
}
</style>
