<template>
  <v-form
    ref="form"
    class="pb-6 pt-2"
  >
    <v-row>
      <v-col cols="12">
        <v-card
          id="sp-form"
          class="pb-6"
          outlined
        >
          <v-card-title>
            {{ editedIndex === null ? 'Ajouter' : 'Modifier' }} un side pocket
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col
                cols="12"
                md="4"
              >
                <v-select
                  id="fonds"
                  v-model="sp.fond_id"
                  :error="errors.fond_id"
                  :items="fondsItems"
                  item-text="nom"
                  item-value="id"
                  label="Fonds"
                  :disabled="disabledFonds"
                  no-data-text="Aucun fond trouvé"
                  outlined
                  dense
                  hide-details
                  @change="fondChange"
                ></v-select>
              </v-col>
              <v-col
                cols="12"
                md="4"
              >
                <v-select
                  id="parts"
                  v-model="sp.part_id"
                  :error="errors.part_id"
                  :items="partsItems"
                  item-text="nom"
                  item-value="id"
                  label="Parts"
                  :disabled="disabledParts"
                  :loading="partsLoading"
                  no-data-text="Aucune part disponible"
                  outlined
                  dense
                  hide-details
                ></v-select>
              </v-col>
              <v-col
                cols="12"
                md="4"
              >
                <v-text-field
                  id="nb-parts"
                  v-model="sp.nb_parts"
                  :error="errors.nb_parts"
                  type="number"
                  min="0"
                  step="0.0001"
                  label="Nombre de parts"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                :md="displayAssureur ? 4 : 6"
              >
                <v-select
                  id="mode-detention"
                  v-model="sp.mode_detention_id"
                  :error="errors.mode_detention_id"
                  :items="detentionOptions"
                  item-text="text"
                  item-value="value"
                  label="Mode de détention"
                  outlined
                  dense
                  hide-details
                  @change="detentionChange"
                ></v-select>
              </v-col>
              <v-col
                cols="12"
                :md="displayAssureur ? 4 : 6"
              >
                <v-combobox
                  id="etablissement"
                  v-model="sp.nom_etablissement"
                  :error="errors.nom_etablissement"
                  :items="etablissementsOptions"
                  :loading="disabledEtablissements"
                  filled
                  clearable
                  hide-details
                  item-text="value"
                  item-value="value"
                  label="Nom de l'établissement"
                  no-data-text="Aucune proposition disponible"
                  outlined
                  dense
                  validate-on-blur
                >
                </v-combobox>
              </v-col>
              <v-col
                v-if="displayAssureur"
                cols="12"
                md="4"
              >
                <v-combobox
                  id="assureur"
                  v-model="sp.assureur"
                  :items="assureurs"
                  filled
                  clearable
                  hide-details
                  item-text="value"
                  item-value="value"
                  label="Assureur"
                  no-data-text="Aucun"
                  outlined
                  dense
                >
                </v-combobox>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  id="numero-contrat"
                  v-model="sp.num_contrat"
                  label="Numéro de contrat"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                Ce contrat fait-il l'objet d'une co-détention/d'un démembrement/d'une indivision ?
                <v-radio-group
                  v-model="sp.indivision"
                  class="mt-0"
                  row
                >
                  <v-radio
                    label="Oui"
                    value="Oui"
                  ></v-radio>
                  <v-radio
                    label="Non"
                    value="Non"
                  ></v-radio>
                  <v-radio
                    label="Je ne sais pas"
                    value="Je ne sais pas"
                  ></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
          </v-card-text>
          <div class="d-flex justify-end pt-1 pr-5">
            <div v-if="editedIndex === null">
              <v-btn
                color="primary"
                :loading="loading.add"
                @click="addSp()"
              >
                Ajouter
              </v-btn>
            </div>
            <div v-else>
              <v-btn
                color="primary"
                :loading="loading.add"
                @click="addSp()"
              >
                Modifier
              </v-btn>
              <v-btn
                color="secondary"
                class="ml-5"
                @click="resetSp()"
              >
                Annuler
              </v-btn>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card
          class="app-card-code mt-5"
        >
          <v-card-title class="no-padding">
            <v-icon
              v-if="spValidated"
              class="mr-2"
              color="success"
            >
              {{ mdiCheckBold }}
            </v-icon>
            Side pocket
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-text class="no-padding">
            <v-data-table
              :headers="tableHeaders"
              :items="sidepockets"
              :items-per-page="-1"
              :item-class="rowClass"
              :single-expand="false"
              :expanded.sync="expanded"
              show-expand
              hide-default-footer
              disable-sort
              no-data-text="Aucun sidepocket"
            >
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                  Numéro de contrat : <strong>{{ item.num_contrat !== null ? item.num_contrat : 'non fourni' }}</strong><br />
                  Ce contrat fait-il l'objet d'une co-détention/d'un démembrement/d'une indivision ? <strong>{{ item.indivision !== null ? item.indivision : 'non communiqué' }}</strong>
                </td>
              </template>
              <template #[`item.nom_fond`]="{ item }">
                <v-icon
                  v-if="item.valid"
                  color="success"
                  size="24"
                  class="mr-2"
                >
                  {{ mdiCheckBold }}
                </v-icon>
                {{ item.nom_fond }}
              </template>

              <template
                #[`item.total_parts`]="{ item }"
                class="text-no-wrap"
              >
                {{ formatTotalParts(item.total_parts) }}
              </template>
              <!-- action -->
              <template #[`item.actions`]="{ item, index }">
                <v-btn
                  icon
                  title="Modifier"
                  @click="editSp(item)"
                >
                  <v-icon
                    size="20"
                  >
                    {{ mdiPencilOutline }}
                  </v-icon>
                </v-btn>
                <v-btn
                  icon
                  title="Supprimer"
                  @click="confirmDelete(index)"
                >
                  <v-icon
                    size="20"
                  >
                    {{ mdiDeleteOutline }}
                  </v-icon>
                </v-btn>
              </template>
              <!-- action -->
              <template #[`item.valid`]="{ item, index }">
                <v-switch
                  v-model="item.valid"
                  color="success"
                  :loading="validation[index] !== undefined ? validation[index].loading : false"
                  :disabled="validation[index] !== undefined ? validation[index].loading : false"
                  @click="setValidation(item, index)"
                ></v-switch>
              </template>
              <template v-slot:body.append>
                <tr>
                  <td
                    colspan="7"
                    class="font-weight-bold text-right"
                  >
                    TOTAL PRÉJUDICE :
                  </td>
                  <td class="text-right font-weight-bold">
                    {{ formatTotalParts(totalPrejudice) }}
                  </td>
                  <td></td>
                  <td></td>
                </tr>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card
          outlined
          :class="{ err: errorsRs, valid: relevesValidated }"
        >
          <v-card-title class="d-flex align-start pr-7">
            <div class="d-flex align-center">
              <v-avatar
                size="40"
                color="secondary"
                class="v-avatar-light-bg secondary--text me-3"
              >
                <v-icon
                  size="25"
                  color="primary"
                >
                  {{ mdiTextBoxCheckOutline }}
                </v-icon>
              </v-avatar>
              <div class="text-success">
                Relevés de situation
                <v-icon
                  v-if="relevesValidated"
                  color="success"
                >
                  {{ mdiCheckBold }}
                </v-icon>
              </div>
            </div>
          </v-card-title>
          <v-card-text>
            <Upload
              ref="rs"
              input-id="releves"
              :document-id="documents.id"
              document-type="fichier_releve"
              :files-validated.sync="relevesValidated"
            ></Upload>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <div class="d-flex justify-space-between pt-7">
      <v-btn
        outlined
        @click="next(1)"
      >
        Précédent
      </v-btn>
    </div>

    <v-dialog
      v-model="deleteAlert"
      max-width="460"
    >
      <v-card>
        <v-card-title class="text-h5 text-center d-block">
          Suppression d'un sidepocket
        </v-card-title>
        <v-card-text>Êtes-vous certain de vouloir supprimer ce sidepocket ? Attention cette action est définitive !</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="mr-2"
            color="secondary"
            outlined
            :disabled="loading.delete"
            @click="deleteAlert = false"
          >
            Annuler
          </v-btn>
          <v-btn
            color="primary"
            outlined
            :loading="loading.delete"
            @click="deleteSp()"
          >
            Oui, supprimer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-form>
</template>

<script>
// eslint-disable-next-line object-curly-newline
// import AppCardActions from '@core/components/v-card/AppCardActions.vue'
import {
  mdiCheckBold, mdiDeleteOutline, mdiPencilOutline, mdiTextBoxCheckOutline,
} from '@mdi/js'
import themeConfig from '@themeConfig'
import {
  computed, defineComponent, onMounted, reactive, ref, toRefs, watch,
} from '@vue/composition-api'
import goTo from 'vuetify/lib/services/goto'
import { easeInOutCubic } from 'vuetify/lib/services/goto/easing-patterns'
import { useState } from 'vuex-composition-helpers'
import store from '@/store/adhesion'
import Upload from '@/components/Upload.vue'
import { getOptionsValue } from './adherents'
import { assureurs } from './dataFields'

export default defineComponent({
  components: {
    Upload,
  },
  props: {
    stepSup: {
      type: Number,
      default: 0,
    },
  },
  setup(props, { emit }) {
    // const { sidepockets, adherent, documents } = useState('adherent', ['sidepockets', 'adherent', 'documents'])

    const { adherent, documents } = useState('adherent', ['adherent', 'documents'])
    const sidepockets = computed({
      get() {
        return store.state.adherent.sidepockets
      },
      set(val) {
        store.commit('adherent/ADD_SP', val)
      },
    })
    const expanded = ref([])
    let updatedItem = null
    const validation = ref([{ loading: false }])
    const relevesValidated = ref(false)
    const errorsRs = ref(false)

    const indexToDelete = ref(null)
    const deleteAlert = ref(false)
    const displayAssureur = ref(false)

    const spValidated = ref(false)
    const loading = ref({
      add: false,
      delete: false,
    })

    // const sidepockets = computed(() => store.state.adherent.sidepockets)

    const totalPrejudice = computed(() => sidepockets.value.reduce((a, b) => a + b.total_parts, 0))

    const state = reactive({
      sp: {
        adherent_id: null,
        fond_id: null,
        part_id: null,
        nom_fond: null,
        nom_part: null,
        nom_etablissement: null,
        nb_parts: 0,
        montant_part: 0,
        total_parts: 0,
        mode_detention_id: null,
        mode_detention: null,
        valid: false,
      },
      errors: {
        fond_id: false,
        part_id: false,
        nom_etablissement: false,
        nb_parts: false,
        mode_detention_id: false,
      },
      fondsItems: [],
      partsItems: [],
      partsLoading: false,
      disabledFonds: true,
      disabledParts: true,
      disabledEtablissements: true,
      detentionOptions: [
        // { value: 1, text: "Contrat d'assurance vie ou de capitalisation français" },
        // { value: 2, text: "Contrat d'assurance vie ou de capitalisation luxembourgeois" },
        // { value: 3, text: 'Compte titre' },
        // { value: 4, text: 'Autre type de détention, contrat étranger, etc...' },
        { value: 1, text: "Contrat d'assurance vie" },
        { value: 2, text: 'Compte titre' },
        { value: 3, text: 'Contrat d’assurance vie ou de capitalisation' },
      ],
      etablissementsOptions: [],
      form: null,
    })

    const editedIndex = ref(null)

    const tableHeaders = [
      { text: 'Fonds', value: 'nom_fond', width: 175 },
      { text: 'Type de parts', value: 'nom_part' },
      { text: 'Qté', value: 'nb_parts', align: 'center' },
      { text: 'Mode de détention', value: 'mode_detention' },
      { text: 'Établissement', value: 'nom_etablissement' },
      { text: 'Assureur', value: 'assureur' },
      { text: 'Préjudice', value: 'total_parts', align: 'right' },
      {
        text: 'ACTIONS', value: 'actions', align: 'center', width: 110,
      },
      { text: 'VALIDÉ', value: 'valid', align: 'center' },
    ]

    let etablissementsOptions = []

    const next = step => {
      updatedItem = null
      emit('next-step', step + props.stepSup)
    }

    const fondChange = e => {
      state.parts = null
      state.disabledParts = true
      state.partsLoading = true
      fetch(`${themeConfig.app.baseURL}data/fonds/${e}/parts`)
        .then(res => res.clone().json())
        .then(res => {
          state.partsItems = res
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          state.disabledParts = false
          state.partsLoading = false
        })
    }

    const getFonds = () => {
      fetch(`${themeConfig.app.baseURL}data/fonds`)
        .then(res => res.clone().json())
        .then(res => {
          state.fondsItems = res
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          state.disabledFonds = false
        })
    }

    const getEtablissements = () => {
      state.disabledEtablissements = true
      fetch(`${themeConfig.app.baseURL}data/etablissements`)
        .then(res => res.clone().json())
        .then(res => {
          etablissementsOptions = res
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          state.disabledEtablissements = false
        })
    }

    const detentionChange = val => {
      state.sp.nom_etablissement = null
      if (val === 1) {
        state.etablissementsOptions = etablissementsOptions // .filter(detention => detention.origine === 'fr')
        displayAssureur.value = true

      // } else if (val === 2) {
      //   state.etablissementsOptions = etablissementsOptions.filter(detention => detention.origine === 'lux')
      } else {
        state.etablissementsOptions = []
        displayAssureur.value = false
      }
    }

    const setAsyncTimeout = (cb, timeout = 0) =>
      // eslint-disable-next-line implicit-arrow-linebreak
      new Promise(resolve => {
        setTimeout(() => {
          cb()
          resolve()
        }, timeout)
      })

    const resetSp = () => {
      state.sp = {
        ...state.sp,
        ...{
          adherent_id: null,
          fond_id: null,
          part_id: null,
          nom_fond: null,
          nom_part: null,
          nom_etablissement: null,
          assureur: null,
          nb_parts: 0,
          montant_part: 0,
          total_parts: 0,
          mode_detention_id: null,
          mode_detention: null,
          num_contrat: null,
          indivision: null,
        },
      }

      displayAssureur.value = false

      editedIndex.value = null
    }

    const addSp = async () => {
      await setAsyncTimeout(() => {
        document.activeElement.blur()
      }, 50)
      let err = false
      let action = ''
      loading.value.add = true

      Object.keys(state.errors).forEach(key => {
        state.errors[key] = false
        if (state.sp[key] === null || state.sp[key] === '' || state.sp[key] === 0) {
          state.errors[key] = true
          err = true
        }
      })

      if (err) {
        loading.value.add = false

        return false
      }

      if (typeof state.sp.nom_etablissement === 'object') {
        state.sp.nom_etablissement = state.sp.nom_etablissement.value
      }
      state.sp.adherent_id = adherent.value.id
      state.sp.mode_detention = getOptionsValue(state.detentionOptions, state.sp.mode_detention_id, 'text', 'value')
      state.sp.nom_fond = getOptionsValue(state.fondsItems, state.sp.fond_id, 'nom', 'id')
      state.sp.nom_part = getOptionsValue(state.partsItems, state.sp.part_id, 'nom', 'id')
      state.sp.montant_part = getOptionsValue(state.partsItems, state.sp.part_id, 'montant', 'id')
      state.sp.total_parts = state.sp.montant_part * Number(state.sp.nb_parts)

      // state.sp.assureurs = assureur.value
      if (editedIndex.value !== null) {
        updatedItem = editedIndex.value
        action = 'updateSidepocket'
      } else {
        state.sp.valid = true
        action = 'addSidepocket'
        updatedItem = sidepockets.value.length
      }

      try {
        await store.dispatch(`adherent/${action}`, { sidepocket: state.sp, index: editedIndex.value })
        if (action === 'addSidepocket') {
          validation.value.push({ loading: false })
        } else {
          sidepockets.value.push({})
          sidepockets.value.splice(-1)
        }

        resetSp()

        // store.commit('adherent/SET_SP_UPDATED', true)
      } catch (e) {
        emit('alert', { type: 'error', msg: `Une erreur est survenue : ${e}` })
      } finally {
        loading.value.add = false
      }

      return true
    }

    const confirmDelete = index => {
      indexToDelete.value = index
      deleteAlert.value = true
    }

    const deleteSp = async () => {
      // const index = sidepockets.value.indexOf(item)
      loading.value.delete = true
      try {
        await store.dispatch('adherent/deleteSidepocket', indexToDelete.value)
        validation.value.splice(indexToDelete.value, 1)
        emit('alert', { type: 'success', msg: 'Sidepocket supprimé !' })
      } catch (e) {
        emit('alert', { type: 'error', msg: `Impossible de supprimer le sidepocket : ${e}` })
      } finally {
        loading.value.delete = false
        indexToDelete.value = null
        deleteAlert.value = false
      }

      // sidepockets.value.splice(index, 1)
    }

    const editSp = item => {
      updatedItem = null
      editedIndex.value = sidepockets.value.indexOf(item)
      if (item.mode_detention_id !== state.sp.mode_detention_id) {
        detentionChange(item.mode_detention_id)
      }
      Object.assign(state.sp, item)
      goTo('#sp-form', {
        duration: 500,
        easing: easeInOutCubic,
      })
    }

    // const resetData = () => {
    //   sidepockets.value = store.getters['adherent/getSidepockets']
    //   state.form.reset()
    // }

    const rowClass = item => {
      const index = sidepockets.value.indexOf(item)
      if (updatedItem === index) {
        return 'updated'
      }

      return ''
    }

    const setValidation = async (item, index) => {
      validation.value[index].loading = true
      try {
        store.dispatch('adherent/validateSp', { index, id: item.id, val: item.valid })
      } catch (e) {
        console.error(e)
        // eslint-disable-next-line no-param-reassign
        item.valid = !item.valid
      } finally {
        validation.value[index].loading = false
      }
      spValidated.value = sidepockets.value.every(sp => sp.valid)
    }

    const formatTotalParts = totalPart => new Intl
      .NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' })
      .format(totalPart)

    watch(() => state.sp.fond_id, (newFond, prevFond) => {
      if (newFond !== prevFond && newFond !== null) {
        fondChange(newFond)
      }
    })

    // watch(() => sidepockets, (newVal, oldVal) => {
    //   console.log('Change : ', newVal, oldVal)
    // }, { deep: true })

    onMounted(() => {
      getFonds()
      getEtablissements()
      // eslint-disable-next-line no-plusplus
      spValidated.value = sidepockets.value.every(sp => sp.valid)

      // eslint-disable-next-line no-plusplus
      for (let i = 1; i <= sidepockets.value.length; i++) {
        validation.value.push({ loading: false })
      }
    })

    return {
      ...toRefs(state),
      editedIndex,
      expanded,
      sidepockets,
      tableHeaders,
      fondChange,
      detentionChange,
      deleteSp,
      editSp,
      addSp,
      resetSp,
      next,

      // resetData,
      mdiDeleteOutline,
      mdiPencilOutline,
      mdiTextBoxCheckOutline,
      mdiCheckBold,
      rowClass,
      setValidation,
      validation,
      relevesValidated,
      documents,
      errorsRs,
      spValidated,
      loading,
      indexToDelete,
      deleteAlert,
      confirmDelete,
      formatTotalParts,
      totalPrejudice,
      displayAssureur,
      assureurs,
    }
  },
})
</script>

<style scoped>
.no-padding {
  padding-left: 0;
  padding-right: 0;
}

.valid {
  border-color: var(--v-success-base)!important;
}
</style>
