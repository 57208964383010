export const paysOptions = [
  { code: 'AF', pays: 'Afghanistan' },
  { code: 'ZA', pays: 'Afrique Du Sud' },
  { code: 'AL', pays: 'Albanie' },
  { code: 'DZ', pays: 'Algérie' },
  { code: 'DE', pays: 'Allemagne' },
  { code: 'AD', pays: 'Andorre' },
  { code: 'AO', pays: 'Angola' },
  { code: 'AI', pays: 'Anguilla' },
  { code: 'AQ', pays: 'Antarctique' },
  { code: 'AG', pays: 'Antigua-Et-Barbuda' },
  { code: 'SA', pays: 'Arabie Saoudite' },
  { code: 'AR', pays: 'Argentine' },
  { code: 'AM', pays: 'Arménie' },
  { code: 'AW', pays: 'Aruba' },
  { code: 'AU', pays: 'Australie' },
  { code: 'AT', pays: 'Autriche' },
  { code: 'AZ', pays: 'Azerbaïdjan' },
  { code: 'BS', pays: 'Bahamas' },
  { code: 'BH', pays: 'Bahreïn' },
  { code: 'BD', pays: 'Bangladesh' },
  { code: 'BB', pays: 'Barbad' },
  { code: 'BE', pays: 'Belgique' },
  { code: 'BZ', pays: 'Belize' },
  { code: 'BJ', pays: 'Bénin' },
  { code: 'BM', pays: 'Bermudes' },
  { code: 'BT', pays: 'Bhoutan' },
  { code: 'BY', pays: 'Biélorussie' },
  { code: 'MM', pays: 'Birmanie' },
  { code: 'BQ', pays: 'Bonaire,  Saint-Eustache Et Saba' },
  { code: 'BA', pays: 'Bosnie-Herzégovine' },
  { code: 'BW', pays: 'Botswana' },
  { code: 'BR', pays: 'Brésil' },
  { code: 'BN', pays: 'Brunei Darussalam' },
  { code: 'BG', pays: 'Bulgarie' },
  { code: 'BF', pays: 'Burkina Faso' },
  { code: 'BI', pays: 'Burundi' },
  { code: 'KH', pays: 'Cambodge' },
  { code: 'CM', pays: 'Cameroun' },
  { code: 'CA', pays: 'Canada' },
  { code: 'CV', pays: 'Cap-Vert' },
  { code: 'CL', pays: 'Chili' },
  { code: 'CN', pays: 'Chine' },
  { code: 'CY', pays: 'Chypre' },
  { code: 'CO', pays: 'Colombie' },
  { code: 'KM', pays: 'Comores' },
  { code: 'CG', pays: 'Congo' },
  { code: 'CR', pays: 'Costa Rica' },
  { code: 'CI', pays: 'Côte D\'Ivoire' },
  { code: 'HR', pays: 'Croatie' },
  { code: 'CU', pays: 'Cuba' },
  { code: 'CW', pays: 'Curaçao' },
  { code: 'DK', pays: 'Denmark' },
  { code: 'DJ', pays: 'Djibouti' },
  { code: 'DM', pays: 'Dominique' },
  { code: 'EG', pays: 'Égypte' },
  { code: 'AE', pays: 'Émirats Arabes Unis' },
  { code: 'EC', pays: 'Équateur' },
  { code: 'ER', pays: 'Érythrée' },
  { code: 'ES', pays: 'Espagne' },
  { code: 'EE', pays: 'Estonie' },
  { code: 'BO', pays: 'État Plurinational De Bolivie' },
  { code: 'FM', pays: 'États Fédérés De Micronésie' },
  { code: 'US', pays: 'États-Unis' },
  { code: 'ET', pays: 'Éthiopie' },
  { code: 'RU', pays: 'Fédération De Russie' },
  { code: 'FJ', pays: 'Fidji' },
  { code: 'FI', pays: 'Finlande' },
  { code: 'FR', pays: 'France' },
  { code: 'GA', pays: 'Gabon' },
  { code: 'GM', pays: 'Gambie' },
  { code: 'GE', pays: 'Géorgie' },
  { code: 'GS', pays: 'Géorgie Du Sud-Et-Les Îles Sandwich Du Sud' },
  { code: 'GH', pays: 'Ghana' },
  { code: 'GI', pays: 'Gibraltar' },
  { code: 'GR', pays: 'Grèce' },
  { code: 'GD', pays: 'Grenade' },
  { code: 'GL', pays: 'Groenland' },
  { code: 'GP', pays: 'Guadeloupe' },
  { code: 'GU', pays: 'Guam' },
  { code: 'GT', pays: 'Guatemala' },
  { code: 'GG', pays: 'Guernesey' },
  { code: 'GN', pays: 'Guinée' },
  { code: 'GQ', pays: 'Guinée Équatoriale' },
  { code: 'GW', pays: 'Guinée-Bissau' },
  { code: 'GY', pays: 'Guyana' },
  { code: 'GF', pays: 'Guyane' },
  { code: 'HT', pays: 'Haïti' },
  { code: 'HN', pays: 'Honduras' },
  { code: 'HK', pays: 'Hong Kong' },
  { code: 'HU', pays: 'Hongrie' },
  { code: 'BV', pays: 'Île Bouvet' },
  { code: 'CX', pays: 'Île Christmas' },
  { code: 'IM', pays: 'Île De Man' },
  { code: 'NF', pays: 'Île Norfolk' },
  { code: 'AX', pays: 'Îles Åland' },
  { code: 'KY', pays: 'Îles Caïmans' },
  { code: 'CC', pays: 'Îles Cocos' },
  { code: 'CK', pays: 'Îles Cook' },
  { code: 'FO', pays: 'Îles Féroé' },
  { code: 'HM', pays: 'Îles Heard-Et-MacDonald' },
  { code: 'FK', pays: 'Îles Malouines' },
  { code: 'MP', pays: 'Îles Mariannes Du Nord' },
  { code: 'MH', pays: 'Îles Marshall' },
  { code: 'UM', pays: 'Îles Mineures Éloignées Des États-Unis' },
  { code: 'SB', pays: 'Îles Salomon' },
  { code: 'TC', pays: 'Îles Turks-Et-Caïcos' },
  { code: 'VG', pays: 'Îles Vierges Britanniques' },
  { code: 'VI', pays: 'Îles Vierges Des États-Unis' },
  { code: 'IN', pays: 'Inde' },
  { code: 'ID', pays: 'Indonésie' },
  { code: 'IQ', pays: 'Irak' },
  { code: 'IE', pays: 'Irlande' },
  { code: 'IS', pays: 'Islande' },
  { code: 'IL', pays: 'Israël' },
  { code: 'IT', pays: 'Italie' },
  { code: 'JM', pays: 'Jamaïque' },
  { code: 'JP', pays: 'Japon' },
  { code: 'JE', pays: 'Jersey' },
  { code: 'JO', pays: 'Jordanie' },
  { code: 'KZ', pays: 'Kazakhstan' },
  { code: 'KE', pays: 'Kenya' },
  { code: 'KG', pays: 'Kirghizistan' },
  { code: 'KI', pays: 'Kiribati' },
  { code: 'KW', pays: 'Koweït' },
  { code: 'LS', pays: 'Lesotho' },
  { code: 'LV', pays: 'Lettonie' },
  { code: 'LB', pays: 'Liban' },
  { code: 'LR', pays: 'Liberia' },
  { code: 'LY', pays: 'Libye' },
  { code: 'LI', pays: 'Liechtenstein' },
  { code: 'LT', pays: 'Lituanie' },
  { code: 'LU', pays: 'Luxembourg' },
  { code: 'MO', pays: 'Macao' },
  { code: 'MK', pays: 'Macédoine' },
  { code: 'MG', pays: 'Madagascar' },
  { code: 'MY', pays: 'Malaisie' },
  { code: 'MW', pays: 'Malawi' },
  { code: 'MV', pays: 'Maldives' },
  { code: 'ML', pays: 'Mali' },
  { code: 'MT', pays: 'Malte' },
  { code: 'MA', pays: 'Maroc' },
  { code: 'MQ', pays: 'Martinique' },
  { code: 'MU', pays: 'Maurice' },
  { code: 'MR', pays: 'Mauritanie' },
  { code: 'YT', pays: 'Mayotte' },
  { code: 'MX', pays: 'Mexique' },
  { code: 'MC', pays: 'Monaco' },
  { code: 'MN', pays: 'Mongolie' },
  { code: 'ME', pays: 'Monténégro' },
  { code: 'MS', pays: 'Montserrat' },
  { code: 'MZ', pays: 'Mozambique' },
  { code: 'NA', pays: 'Namibie' },
  { code: 'NR', pays: 'Nauru' },
  { code: 'NP', pays: 'Népal' },
  { code: 'NI', pays: 'Nicaragua' },
  { code: 'NE', pays: 'Niger' },
  { code: 'NG', pays: 'Nigéria' },
  { code: 'NU', pays: 'Niue' },
  { code: 'NO', pays: 'Norvège' },
  { code: 'NC', pays: 'Nouvelle-Calédonie' },
  { code: 'NZ', pays: 'Nouvelle-Zélande' },
  { code: 'OM', pays: 'Oman' },
  { code: 'UG', pays: 'Ouganda' },
  { code: 'UZ', pays: 'Ouzbékistan' },
  { code: 'PK', pays: 'Pakistan' },
  { code: 'PW', pays: 'Palaos' },
  { code: 'PA', pays: 'Panama' },
  { code: 'PG', pays: 'Papouasie-Nouvelle-Guinée' },
  { code: 'PY', pays: 'Paraguay' },
  { code: 'NL', pays: 'Pays-Bas' },
  { code: 'PE', pays: 'Pérou' },
  { code: 'PH', pays: 'Philippines' },
  { code: 'PN', pays: 'Pitcairn' },
  { code: 'PL', pays: 'Pologne' },
  { code: 'PF', pays: 'Polynésie Française' },
  { code: 'PR', pays: 'Porto Rico' },
  { code: 'PT', pays: 'Portugal' },
  { code: 'QA', pays: 'Qatar' },
  { code: 'AP', pays: 'Région Asie/Pacifique' },
  { code: 'SY', pays: 'République Arabe Syrienne' },
  { code: 'CF', pays: 'République Centrafricaine' },
  { code: 'KR', pays: 'République De Corée' },
  { code: 'MD', pays: 'République De Moldavie' },
  { code: 'CD', pays: 'République Démocratique Du Congo' },
  { code: 'LA', pays: 'République Démocratique Populaire Lao' },
  { code: 'DO', pays: 'République Dominicaine' },
  { code: 'SV', pays: 'République Du Salvador' },
  { code: 'IR', pays: 'République Islamique D\'Iran' },
  { code: 'KP', pays: 'République Populaire Démocratique De Corée' },
  { code: 'CZ', pays: 'République Tchèque' },
  { code: 'TZ', pays: 'République-Unie De Tanzanie' },
  { code: 'RE', pays: 'Réunion' },
  { code: 'RO', pays: 'Roumanie' },
  { code: 'GB', pays: 'Royaume-Uni' },
  { code: 'RW', pays: 'Rwanda' },
  { code: 'EH', pays: 'Sahara Occidental' },
  { code: 'BL', pays: 'Saint-Barthélemy' },
  { code: 'KN', pays: 'Saint-Christophe-et-Niévès' },
  { code: 'SM', pays: 'Saint-Marin' },
  { code: 'MF', pays: 'Saint-Martin (Partie Française)' },
  { code: 'SX', pays: 'Saint-Martin (Partie Néerlandaise)' },
  { code: 'PM', pays: 'Saint-Pierre-Et-Miquelon' },
  { code: 'VA', pays: 'Saint-Siège (État De La Cité Du Vatican)' },
  { code: 'VC', pays: 'Saint-Vincent-Et-Les Grenadines' },
  { code: 'SH', pays: 'Sainte-Hélène' },
  { code: 'LC', pays: 'Sainte-Lucie' },
  { code: 'WS', pays: 'Samoa' },
  { code: 'AS', pays: 'Samoa Américaines' },
  { code: 'ST', pays: 'Sao Tomé-Et-Principe' },
  { code: 'SN', pays: 'Sénégal' },
  { code: 'RS', pays: 'Serbie' },
  { code: 'SC', pays: 'Seychelles' },
  { code: 'SL', pays: 'Sierra Leone' },
  { code: 'SG', pays: 'Singapour' },
  { code: 'SK', pays: 'Slovaquie' },
  { code: 'SI', pays: 'Slovénie' },
  { code: 'SO', pays: 'Somalie' },
  { code: 'SD', pays: 'Soudan' },
  { code: 'SS', pays: 'Soudan Du Sud' },
  { code: 'LK', pays: 'Sri Lanka' },
  { code: 'SE', pays: 'Suède' },
  { code: 'CH', pays: 'Suisse' },
  { code: 'SR', pays: 'Suriname' },
  { code: 'SJ', pays: 'Svalbard Et Jan Mayen' },
  { code: 'SZ', pays: 'Swaziland' },
  { code: 'TJ', pays: 'Tadjikistan' },
  { code: 'TW', pays: 'Taïwan' },
  { code: 'TD', pays: 'Tchad' },
  { code: 'TF', pays: 'Terres Australes Françaises' },
  { code: 'IO', pays: 'Territoire Britannique De L\'océan Indien' },
  { code: 'PS', pays: 'Territoires Palestiniens Occupés' },
  { code: 'TH', pays: 'Thaïlande' },
  { code: 'TL', pays: 'Timor-Leste' },
  { code: 'TG', pays: 'Togo' },
  { code: 'TK', pays: 'Tokelau' },
  { code: 'TO', pays: 'Tonga' },
  { code: 'TT', pays: 'Trinité-Et-Tobago' },
  { code: 'TN', pays: 'Tunisie' },
  { code: 'TM', pays: 'Turkménistan' },
  { code: 'TR', pays: 'Turquie' },
  { code: 'TV', pays: 'Tuvalu' },
  { code: 'UA', pays: 'Ukraine' },
  { code: 'UY', pays: 'Uruguay' },
  { code: 'VU', pays: 'Vanuatu' },
  { code: 'VE', pays: 'Venezuela' },
  { code: 'VN', pays: 'Viet Nam' },
  { code: 'WF', pays: 'Wallis Et Futuna' },
  { code: 'YE', pays: 'Yémen' },
  { code: 'ZM', pays: 'Zambie' },
  { code: 'ZW', pays: 'Zimbabwe' },
]

export const nationalitesOptions = [
  { id: 1, code: 'AFG', nom: 'Afghane (Afghanistan)' },
  { id: 2, code: 'ALB', nom: 'Albanaise (Albanie)' },
  { id: 3, code: 'DZA', nom: 'Algérienne (Algérie)' },
  { id: 4, code: 'DEU', nom: 'Allemande (Allemagne)' },
  { id: 5, code: 'USA', nom: 'Americaine (États-Unis)' },
  { id: 6, code: 'AND', nom: 'Andorrane (Andorre)' },
  { id: 7, code: 'AGO', nom: 'Angolaise (Angola)' },
  { id: 8, code: 'ATG', nom: 'Antiguaise-et-Barbudienne (Antigua-et-Barbuda)' },
  { id: 9, code: 'ARG', nom: 'Argentine (Argentine)' },
  { id: 10, code: 'ARM', nom: 'Armenienne (Arménie)' },
  { id: 11, code: 'AUS', nom: 'Australienne (Australie)' },
  { id: 12, code: 'AUT', nom: 'Autrichienne (Autriche)' },
  { id: 13, code: 'AZE', nom: 'Azerbaïdjanaise (Azerbaïdjan)' },
  { id: 14, code: 'BHS', nom: 'Bahamienne (Bahamas)' },
  { id: 15, code: 'BHR', nom: 'Bahreinienne (Bahreïn)' },
  { id: 16, code: 'BGD', nom: 'Bangladaise (Bangladesh)' },
  { id: 17, code: 'BRB', nom: 'Barbadienne (Barbade)' },
  { id: 18, code: 'BEL', nom: 'Belge (Belgique)' },
  { id: 19, code: 'BLZ', nom: 'Belizienne (Belize)' },
  { id: 20, code: 'BEN', nom: 'Béninoise (Bénin)' },
  { id: 21, code: 'BTN', nom: 'Bhoutanaise (Bhoutan)' },
  { id: 22, code: 'BLR', nom: 'Biélorusse (Biélorussie)' },
  { id: 23, code: 'MMR', nom: 'Birmane (Birmanie)' },
  { id: 24, code: 'GNB', nom: 'Bissau-Guinéenne (Guinée-Bissau)' },
  { id: 25, code: 'BOL', nom: 'Bolivienne (Bolivie)' },
  { id: 26, code: 'BIH', nom: 'Bosnienne (Bosnie-Herzégovine)' },
  { id: 27, code: 'BWA', nom: 'Botswanaise (Botswana)' },
  { id: 28, code: 'BRA', nom: 'Brésilienne (Brésil)' },
  { id: 29, code: 'GBR', nom: 'Britannique (Royaume-Uni)' },
  { id: 30, code: 'BRN', nom: 'Brunéienne (Brunéi)' },
  { id: 31, code: 'BGR', nom: 'Bulgare (Bulgarie)' },
  { id: 32, code: 'BFA', nom: 'Burkinabée (Burkina)' },
  { id: 33, code: 'BDI', nom: 'Burundaise (Burundi)' },
  { id: 34, code: 'KHM', nom: 'Cambodgienne (Cambodge)' },
  { id: 35, code: 'CMR', nom: 'Camerounaise (Cameroun)' },
  { id: 36, code: 'CAN', nom: 'Canadienne (Canada)' },
  { id: 37, code: 'CPV', nom: 'Cap-verdienne (Cap-Vert)' },
  { id: 38, code: 'CAF', nom: 'Centrafricaine (Centrafrique)' },
  { id: 39, code: 'CHL', nom: 'Chilienne (Chili)' },
  { id: 40, code: 'CHN', nom: 'Chinoise (Chine)' },
  { id: 41, code: 'CYP', nom: 'Chypriote (Chypre)' },
  { id: 42, code: 'COL', nom: 'Colombienne (Colombie)' },
  { id: 43, code: 'COM', nom: 'Comorienne (Comores)' },
  { id: 44, code: 'COG', nom: 'Congolaise (Congo-Brazzaville)' },
  { id: 45, code: 'COD', nom: 'Congolaise (Congo-Kinshasa)' },
  { id: 46, code: 'COK', nom: 'Cookienne (Îles Cook)' },
  { id: 47, code: 'CRI', nom: 'Costaricaine (Costa Rica)' },
  { id: 48, code: 'HRV', nom: 'Croate (Croatie)' },
  { id: 49, code: 'CUB', nom: 'Cubaine (Cuba)' },
  { id: 50, code: 'DNK', nom: 'Danoise (Danemark)' },
  { id: 51, code: 'DJI', nom: 'Djiboutienne (Djibouti)' },
  { id: 52, code: 'DOM', nom: 'Dominicaine (République dominicaine)' },
  { id: 53, code: 'DMA', nom: 'Dominiquaise (Dominique)' },
  { id: 54, code: 'EGY', nom: 'Égyptienne (Égypte)' },
  { id: 55, code: 'ARE', nom: 'Émirienne (Émirats arabes unis)' },
  { id: 56, code: 'GNQ', nom: 'Équato-guineenne (Guinée équatoriale)' },
  { id: 57, code: 'ECU', nom: 'Équatorienne (Équateur)' },
  { id: 58, code: 'ERI', nom: 'Érythréenne (Érythrée)' },
  { id: 59, code: 'ESP', nom: 'Espagnole (Espagne)' },
  { id: 60, code: 'TLS', nom: 'Est-timoraise (Timor-Leste)' },
  { id: 61, code: 'EST', nom: 'Estonienne (Estonie)' },
  { id: 62, code: 'ETH', nom: 'Éthiopienne (Éthiopie)' },
  { id: 63, code: 'FJI', nom: 'Fidjienne (Fidji)' },
  { id: 64, code: 'FIN', nom: 'Finlandaise (Finlande)' },
  { id: 65, code: 'FRA', nom: 'Française (France)' },
  { id: 66, code: 'GAB', nom: 'Gabonaise (Gabon)' },
  { id: 67, code: 'GMB', nom: 'Gambienne (Gambie)' },
  { id: 68, code: 'GEO', nom: 'Georgienne (Géorgie)' },
  { id: 69, code: 'GHA', nom: 'Ghanéenne (Ghana)' },
  { id: 70, code: 'GRD', nom: 'Grenadienne (Grenade)' },
  { id: 71, code: 'GTM', nom: 'Guatémaltèque (Guatemala)' },
  { id: 72, code: 'GIN', nom: 'Guinéenne (Guinée)' },
  { id: 73, code: 'GUY', nom: 'Guyanienne (Guyana)' },
  { id: 74, code: 'HTI', nom: 'Haïtienne (Haïti)' },
  { id: 75, code: 'GRC', nom: 'Hellénique (Grèce)' },
  { id: 76, code: 'HND', nom: 'Hondurienne (Honduras)' },
  { id: 77, code: 'HUN', nom: 'Hongroise (Hongrie)' },
  { id: 78, code: 'IND', nom: 'Indienne (Inde)' },
  { id: 79, code: 'IDN', nom: 'Indonésienne (Indonésie)' },
  { id: 80, code: 'IRQ', nom: 'Irakienne (Iraq)' },
  { id: 81, code: 'IRN', nom: 'Iranienne (Iran)' },
  { id: 82, code: 'IRL', nom: 'Irlandaise (Irlande)' },
  { id: 83, code: 'ISL', nom: 'Islandaise (Islande)' },
  { id: 84, code: 'ISR', nom: 'Israélienne (Israël)' },
  { id: 85, code: 'ITA', nom: 'Italienne (Italie)' },
  { id: 86, code: 'CIV', nom: 'Ivoirienne (Côte d\tIvoire)' },
  { id: 87, code: 'JAM', nom: 'Jamaïcaine (Jamaïque)' },
  { id: 88, code: 'JPN', nom: 'Japonaise (Japon)' },
  { id: 89, code: 'JOR', nom: 'Jordanienne (Jordanie)' },
  { id: 90, code: 'KAZ', nom: 'Kazakhstanaise (Kazakhstan)' },
  { id: 91, code: 'KEN', nom: 'Kenyane (Kenya)' },
  { id: 92, code: 'KGZ', nom: 'Kirghize (Kirghizistan)' },
  { id: 93, code: 'KIR', nom: 'Kiribatienne (Kiribati)' },
  { id: 94, code: 'KNA', nom: 'Kittitienne et Névicienne (Saint-Christophe-et-Niévès)' },
  { id: 95, code: 'KWT', nom: 'Koweïtienne (Koweït)' },
  { id: 96, code: 'LAO', nom: 'Laotienne (Laos)' },
  { id: 97, code: 'LSO', nom: 'Lesothane (Lesotho)' },
  { id: 98, code: 'LVA', nom: 'Lettone (Lettonie)' },
  { id: 99, code: 'LBN', nom: 'Libanaise (Liban)' },
  { id: 100, code: 'LBR', nom: 'Libérienne (Libéria)' },
  { id: 101, code: 'LBY', nom: 'Libyenne (Libye)' },
  { id: 102, code: 'LIE', nom: 'Liechtensteinoise (Liechtenstein)' },
  { id: 103, code: 'LTU', nom: 'Lituanienne (Lituanie)' },
  { id: 104, code: 'LUX', nom: 'Luxembourgeoise (Luxembourg)' },
  { id: 105, code: 'MKD', nom: 'Macédonienne (Macédoine)' },
  { id: 106, code: 'MYS', nom: 'Malaisienne (Malaisie)' },
  { id: 107, code: 'MWI', nom: 'Malawienne (Malawi)' },
  { id: 108, code: 'MDV', nom: 'Maldivienne (Maldives)' },
  { id: 109, code: 'MDG', nom: 'Malgache (Madagascar)' },
  { id: 110, code: 'MLI', nom: 'Maliennes (Mali)' },
  { id: 111, code: 'MLT', nom: 'Maltaise (Malte)' },
  { id: 112, code: 'MAR', nom: 'Marocaine (Maroc)' },
  { id: 113, code: 'MHL', nom: 'Marshallaise (Îles Marshall)' },
  { id: 114, code: 'MUS', nom: 'Mauricienne (Maurice)' },
  { id: 115, code: 'MRT', nom: 'Mauritanienne (Mauritanie)' },
  { id: 116, code: 'MEX', nom: 'Mexicaine (Mexique)' },
  { id: 117, code: 'FSM', nom: 'Micronésienne (Micronésie)' },
  { id: 118, code: 'MDA', nom: 'Moldave (Moldovie)' },
  { id: 119, code: 'MCO', nom: 'Monegasque (Monaco)' },
  { id: 120, code: 'MNG', nom: 'Mongole (Mongolie)' },
  { id: 121, code: 'MNE', nom: 'Monténégrine (Monténégro)' },
  { id: 122, code: 'MOZ', nom: 'Mozambicaine (Mozambique)' },
  { id: 123, code: 'NAM', nom: 'Namibienne (Namibie)' },
  { id: 124, code: 'NRU', nom: 'Nauruane (Nauru)' },
  { id: 125, code: 'NLD', nom: 'Néerlandaise (Pays-Bas)' },
  { id: 126, code: 'NZL', nom: 'Néo-Zélandaise (Nouvelle-Zélande)' },
  { id: 127, code: 'NPL', nom: 'Népalaise (Népal)' },
  { id: 128, code: 'NIC', nom: 'Nicaraguayenne (Nicaragua)' },
  { id: 129, code: 'NGA', nom: 'Nigériane (Nigéria)' },
  { id: 130, code: 'NER', nom: 'Nigérienne (Niger)' },
  { id: 131, code: 'NIU', nom: 'Niuéenne (Niue)' },
  { id: 132, code: 'PRK', nom: 'Nord-coréenne (Corée du Nord)' },
  { id: 133, code: 'NOR', nom: 'Norvégienne (Norvège)' },
  { id: 134, code: 'OMN', nom: 'Omanaise (Oman)' },
  { id: 135, code: 'UGA', nom: 'Ougandaise (Ouganda)' },
  { id: 136, code: 'UZB', nom: 'Ouzbéke (Ouzbékistan)' },
  { id: 137, code: 'PAK', nom: 'Pakistanaise (Pakistan)' },
  { id: 138, code: 'PLW', nom: 'Palaosienne (Palaos)' },
  { id: 139, code: 'PSE', nom: 'Palestinienne (Palestine)' },
  { id: 140, code: 'PAN', nom: 'Panaméenne (Panama)' },
  { id: 141, code: 'PNG', nom: 'Papouane-Néo-Guinéenne (Papouasie-Nouvelle-Guinée)' },
  { id: 142, code: 'PRY', nom: 'Paraguayenne (Paraguay)' },
  { id: 143, code: 'PER', nom: 'Péruvienne (Pérou)' },
  { id: 144, code: 'PHL', nom: 'Philippine (Philippines)' },
  { id: 145, code: 'POL', nom: 'Polonaise (Pologne)' },
  { id: 146, code: 'PRT', nom: 'Portugaise (Portugal)' },
  { id: 147, code: 'QAT', nom: 'Qatarienne (Qatar)' },
  { id: 148, code: 'ROU', nom: 'Roumaine (Roumanie)' },
  { id: 149, code: 'RUS', nom: 'Russe (Russie)' },
  { id: 150, code: 'RWA', nom: 'Rwandaise (Rwanda)' },
  { id: 151, code: 'LCA', nom: 'Saint-Lucienne (Sainte-Lucie)' },
  { id: 152, code: 'SMR', nom: 'Saint-Marinaise (Saint-Marin)' },
  { id: 153, code: 'VCT', nom: 'Saint-Vincentaise et Grenadine (Saint-Vincent-et-les Grenadines)' },
  { id: 154, code: 'SLB', nom: 'Salomonaise (Îles Salomon)' },
  { id: 155, code: 'SLV', nom: 'Salvadorienne (Salvador)' },
  { id: 156, code: 'WSM', nom: 'Samoane (Samoa)' },
  { id: 157, code: 'STP', nom: 'Santoméenne (Sao Tomé-et-Principe)' },
  { id: 158, code: 'SAU', nom: 'Saoudienne (Arabie saoudite)' },
  { id: 159, code: 'SEN', nom: 'Sénégalaise (Sénégal)' },
  { id: 160, code: 'SRB', nom: 'Serbe (Serbie)' },
  { id: 161, code: 'SYC', nom: 'Seychelloise (Seychelles)' },
  { id: 162, code: 'SLE', nom: 'Sierra-Léonaise (Sierra Leone)' },
  { id: 163, code: 'SGP', nom: 'Singapourienne (Singapour)' },
  { id: 164, code: 'SVK', nom: 'Slovaque (Slovaquie)' },
  { id: 165, code: 'SVN', nom: 'Slovène (Slovénie)' },
  { id: 166, code: 'SOM', nom: 'Somalienne (Somalie)' },
  { id: 167, code: 'SDN', nom: 'Soudanaise (Soudan)' },
  { id: 168, code: 'LKA', nom: 'Sri-Lankaise (Sri Lanka)' },
  { id: 169, code: 'ZAF', nom: 'Sud-Africaine (Afrique du Sud)' },
  { id: 170, code: 'KOR', nom: 'Sud-Coréenne (Corée du Sud)' },
  { id: 171, code: 'SSD', nom: 'Sud-Soudanaise (Soudan du Sud)' },
  { id: 172, code: 'SWE', nom: 'Suédoise (Suède)' },
  { id: 173, code: 'CHE', nom: 'Suisse (Suisse)' },
  { id: 174, code: 'SUR', nom: 'Surinamaise (Suriname)' },
  { id: 175, code: 'SWZ', nom: 'Swazie (Swaziland)' },
  { id: 176, code: 'SYR', nom: 'Syrienne (Syrie)' },
  { id: 177, code: 'TJK', nom: 'Tadjike (Tadjikistan)' },
  { id: 178, code: 'TZA', nom: 'Tanzanienne (Tanzanie)' },
  { id: 179, code: 'TCD', nom: 'Tchadienne (Tchad)' },
  { id: 180, code: 'CZE', nom: 'Tchèque (Tchéquie)' },
  { id: 181, code: 'THA', nom: 'Thaïlandaise (Thaïlande)' },
  { id: 182, code: 'TGO', nom: 'Togolaise (Togo)' },
  { id: 183, code: 'TON', nom: 'Tonguienne (Tonga)' },
  { id: 184, code: 'TTO', nom: 'Trinidadienne (Trinité-et-Tobago)' },
  { id: 185, code: 'TUN', nom: 'Tunisienne (Tunisie)' },
  { id: 186, code: 'TKM', nom: 'Turkmène (Turkménistan)' },
  { id: 187, code: 'TUR', nom: 'Turque (Turquie)' },
  { id: 188, code: 'TUV', nom: 'Tuvaluane (Tuvalu)' },
  { id: 189, code: 'UKR', nom: 'Ukrainienne (Ukraine)' },
  { id: 190, code: 'URY', nom: 'Uruguayenne (Uruguay)' },
  { id: 191, code: 'VUT', nom: 'Vanuatuane (Vanuatu)' },
  { id: 192, code: 'VAT', nom: 'Vaticane (Vatican)' },
  { id: 193, code: 'VEN', nom: 'Vénézuélienne (Venezuela)' },
  { id: 194, code: 'VNM', nom: 'Vietnamienne (Viêt Nam)' },
  { id: 195, code: 'YEM', nom: 'Yéménite (Yémen)' },
  { id: 196, code: 'ZMB', nom: 'Zambienne (Zambie)' },
  { id: 197, code: 'ZWE', nom: 'Zimbabwéenne (Zimbabwe)' },
]
