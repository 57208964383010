<template>
  <div>
    <v-form
      :id="`indivision-${index}`"
      ref="form"
    >
      <v-snackbar
        v-model="formError"
        :timeout="5000"
        color="error"
        top
      >
        {{ errorText }}
      </v-snackbar>
      <v-row>
        <v-col>
          <v-btn
            outlined
            small
            @click="copy"
          >
            <v-icon left>
              {{ mdiContentCopy }}
            </v-icon>
            Copier les coordonnées
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="2"
        >
          <v-select
            v-model="indivisionProps.civilite"
            :items="['M.', 'Mme.', 'Mr. & Mme.', 'indivision']"
            :rules="[required]"
            label="Civilité"
            outlined
            dense
            hide-details
          ></v-select>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="indivisionProps.nom"
            label="Nom"
            :rules="[required]"
            outlined
            dense
            hide-details
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="indivisionProps.prenom"
            label="Prénom"
            :rules="[required]"
            outlined
            dense
            hide-details
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-menu
            ref="menuref"
            v-model="datepicker"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="indivisionProps.date_de_naissance"
                class="hide-detail"
                label="Date de naissance"
                :rules="[dateValidator]"
                v-bind="attrs"
                outlined
                dense
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="date"
              no-title
              color="primary"
              locale="fr-fr"
              @input="datepicker = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="indivisionProps.ville_naissance"
            label="Ville de naissance"
            :rules="[required]"
            outlined
            dense
            hide-details
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-select
            v-model="indivisionProps.nationalite_id"
            :items="nationalitesOptions"
            item-text="nom"
            item-value="id"
            label="Nationalité"
            :rules="[required]"
            outlined
            dense
            hide-details
          ></v-select>
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="indivisionProps.profession"
            label="Profession"
            :rules="[required]"
            outlined
            dense
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
        >
          <v-text-field
            v-model="indivisionProps.adresse1"
            label="Numéro et libellé de voie"
            :rules="[required]"
            outlined
            dense
            hide-details
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
        >
          <v-text-field
            v-model="indivisionProps.adresse2"
            label="Lieu dit ou service particulier de distribution"
            outlined
            dense
            hide-details
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="indivisionProps.codepostal"
            label="Code postal"
            :rules="[required]"
            outlined
            dense
            hide-details
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="indivisionProps.ville"
            label="Ville"
            :rules="[required]"
            outlined
            dense
            hide-details
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-select
            v-model="indivisionProps.code_pays"
            :items="paysOptions"
            item-text="pays"
            item-value="code"
            label="Pays"
            :rules="[required]"
            outlined
            dense
            hide-details
          ></v-select>
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="indivisionProps.telephone"
            label="Téléphone"
            :rules="[required]"
            outlined
            dense
            hide-details
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
        >
          <v-text-field
            v-model="indivisionProps.email"
            label="E-mail"
            :rules="[required,emailValidator]"
            outlined
            dense
            hide-details
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          class="text-center"
        >
          <v-btn
            class="mr-2"
            color="secondary"
            outlined
            @click="deleteDialog = true"
          >
            Supprimer
          </v-btn>
          <v-btn
            class="ml-2"
            color="primary"
            :loading="loading.save"
            @click="save"
          >
            <span v-if="indivisionProps.id === null">Ajouter</span>
            <span v-else>Modifier</span>
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
    <v-divider
      :thickness="1"
      class="my-15"
    ></v-divider>
    <v-dialog
      v-model="deleteDialog"
      max-width="460"
    >
      <v-card>
        <v-card-title class="text-h5 text-center d-block">
          Suppression de la fiche<br />de {{ indivisionProps.nom }} {{ indivisionProps.prenom }}
        </v-card-title>
        <v-card-text>Êtes-vous certain de vouloir supprimer les données de cette personne ? Attention cette action est définitive !</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="mr-2"
            color="secondary"
            outlined
            :disabled="loading.delete"
            @click="deleteDialog = false"
          >
            Annuler
          </v-btn>
          <v-btn
            color="primary"
            outlined
            :loading="loading.delete"
            @click="deleteIndivision()"
          >
            Oui, supprimer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  ref, defineComponent, watch, onMounted,
} from '@vue/composition-api'
import {
  dateValidator, emailValidator, required,
} from '@core/utils/validation'
import goTo from 'vuetify/lib/services/goto'
import { easeInOutCubic } from 'vuetify/lib/services/goto/easing-patterns'
import {
  mdiContentCopy,
} from '@mdi/js'
import store from '@/store/adhesion'
import { getOptionsValue } from '../adherents'
import { nationalitesOptions, paysOptions } from '@/data'

export default defineComponent({
  name: 'Indivision',
  props: {
    indivision: {
      type: Object,
      required: true,
    },
    adherent: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { indivision: indivisionProps, adherent, index } = props
    const date = ref(new Date().toISOString().substr(0, 10))
    const loading = ref({
      save: false,
      delete: false,
    })
    const deleteDialog = ref(false)
    const datepicker = ref(false)
    const form = ref(null)
    const formError = ref(false)
    const errorText = ref('')

    const save = async () => {
      formError.value = false
      errorText.value = ''
      if (!form.value.validate()) {
        formError.value = true
        errorText.value = 'Veuillez vérifier les champs du formulaire'

        return
      }
      loading.value.save = true
      try {
        indivisionProps.adherent_id = adherent.id
        indivisionProps.pays = getOptionsValue(paysOptions, indivisionProps.code_pays, 'pays')
        const msg = indivisionProps.id === null ? 'Ajout' : 'Modification'
        indivisionProps.id = await store.dispatch('adherent/saveIndivision', { adherentId: adherent.id, indivision: indivisionProps, index })
        emit('alert', { type: 'success', msg: `${msg} effectuée !` })
      } catch (e) {
        console.error('error', e)
        emit('alert', { type: 'error', msg: e })
      } finally {
        loading.value.save = false
      }
    }

    const copy = () => {
      indivisionProps.nationalite_id = adherent.nationalite_id
      indivisionProps.adresse1 = adherent.adresse1
      indivisionProps.adresse2 = adherent.adresse2
      indivisionProps.codepostal = adherent.codepostal
      indivisionProps.ville = adherent.ville
      indivisionProps.code_pays = adherent.code_pays
      indivisionProps.pays = adherent.pays
      indivisionProps.telephone = adherent.telephone
      indivisionProps.email = adherent.email
    }

    const deleteIndivision = () => {
      emit('delete-indivision', { indivision: indivisionProps, index })
    }

    watch(date, () => {
      const dateNaissance = date.value.split('-')
      indivisionProps.date_de_naissance = `${dateNaissance[2]}/${dateNaissance[1]}/${dateNaissance[0]}`
    })

    onMounted(() => {
      if (indivisionProps.id === null) {
        goTo(`#indivision-${index}`, {
          duration: 500,
          easing: easeInOutCubic,
        })
      }
    })

    return {
      indivisionProps,
      nationalitesOptions,
      datepicker,
      date,
      paysOptions,
      required,
      emailValidator,
      dateValidator,
      save,
      deleteIndivision,
      loading,
      deleteDialog,
      form,
      formError,
      errorText,
      copy,
      mdiContentCopy,
    }
  },

})
</script>

<style lang="scss" scoped>

</style>
