/* eslint-disable object-curly-newline */
import axios from '@axios'
import { sqlDateConvert } from '@/views/application/adherents/adherents'
import { adherent as _adherent, documents as _documents, sidepockets as _sidepockets, adhesion, indivision, representantLegal } from './data'

const getDefaultState = () => ({
  adhesion,
  adherent: _adherent,
  indivisions: [],
  representantLegal,
  sidepockets: _sidepockets,
  documents: _documents,
  hasRepresentant: false,
  spUpdated: false,
})

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    getAdhesion: state => state.adhesion,
    getAdherent: state => state.adherent,
    getIndivisions: state => state.indivisions,
    getRepresentantLegal: state => state.representantLegal,
    getDocuments: state => state.documents,
    getSidepockets: state => state.sidepockets,
    getHasRepresentant: state => state.hasRepresentant,
    getSpUpdated: state => state.spUpdated,
  },
  mutations: {
    RESET_ALL(state) {
      Object.assign(state, getDefaultState())
    },
    SET_ADHESION(state, value) {
      state.adhesion = value
    },
    SET_ADHERENT(state, value) {
      state.adherent = value
    },
    SET_DOCUMENTS(state, value) {
      state.documents = value
    },
    ADD_DOCUMENT(state, { type, data }) {
      state.documents[type].push(data)
    },
    UPDATE_DOCUMENT(state, { type, index, data }) {
      state.documents[type][index] = { ...state.documents[type][index], ...data }
    },
    SET_REPRESENTANT_LEGAL(state, value) {
      state.representantLegal = value
    },
    UPDATE_REPRESENTANT(state, { values, index }) {
      state.representantLegal[index] = { ...state.representantLegal[index], ...values }
    },
    SET_DATA(state, { prop, values }) {
      state[prop] = { ...state[prop], ...values }
    },
    SET_HAS_REPRESENTANT(state, value) {
      state.hasRepresentant = value
    },
    SET_SP(state, sidepockets) {
      state.sidepockets = sidepockets
    },
    SET_INDIVISIONS(state, indivisions) {
      state.indivisions = indivisions
    },
    SET_SP_UPDATED(state, status) {
      state.spUpdated = status
    },
    ADD_SP(state, sidepocket) {
      state.sidepockets.push(sidepocket)
    },
    ADD_REPRESENTANT(state) {
      state.representantLegal.push({ ...representantLegal })
    },
    ADD_INDIVISION(state) {
      state.indivisions.push({ ...indivision })
    },
    UPDATE_INDIVISION(state, { values, index }) {
      state.indivisions[index] = { ...state.indivisions[index], ...values }
    },
    UPDATE_SP(state, { sidepocket, index }) {
      state.sidepockets[index] = { ...state.sidepockets[index], ...sidepocket }
    },
    DELETE_SP(state, index) {
      state.sidepockets.splice(index, 1)
    },
    DELETE_INDIVISION(state, index) {
      state.indivisions.splice(index, 1)
    },
    DELETE_ALL_SP(state) {
      state.sidepockets = []
    },
    SET_VALIDATION(state, val) {
      state.adhesion.validation = val
    },
    SET_ADHERENT_CHECKED(state, val) {
      state.adhesion.adherent_checked = val
    },
    SET_REPRESENTANT_CHECKED(state, val) {
      state.adhesion.representant_checked = val
    },
    SET_VALIDITE_PI(state, val) {
      state.documents.validite_pi = val
    },
    SET_TYPE_PI(state, val) {
      state.documents.type_pi = val
    },
    SET_DOCUMENT_VALIDATED(state, { type, index, val }) {
      state.documents[type][index].validated = val
    },
    SET_SP_VALIDATED(state, { index, val }) {
      state.sidepockets[index].valid = val
    },
    SET_NOTES(state, val) {
      state.adhesion.notes = val
    },
    UPDATE_CGP(state, val) {
      state.adhesion.bon_id = val
    },
    UPDATE_LANG(state, val) {
      state.adhesion.lang = val
    },
    SET_LDM(state, val) {
      state.adhesion.ldm = val
    },
  },
  actions: {
    async fetchData({ commit }, id) {
      try {
        const data = await axios.get(`/adhesions/show/${id}`)
        const { adherent: adherentData, ...adhesionData } = data.data.data
        const { sidepockets, documents, representantLegal: representantLegalData, indivisions, ...adherent } = adherentData
        commit('SET_ADHESION', adhesionData)
        commit('SET_ADHERENT', adherent)
        commit('SET_SP', sidepockets)
        commit('SET_INDIVISIONS', indivisions)
        commit('SET_DOCUMENTS', documents)
        if (representantLegalData !== null) {
          commit('SET_REPRESENTANT_LEGAL', representantLegalData)
        }
        commit('SET_HAS_REPRESENTANT', representantLegalData !== null)
      } catch (error) {
        // eslint-disable-next-line no-alert
        alert(error)
        console.error(error)
      }
    },
    resetAll({ commit }) {
      commit('RESET_ALL')

      // commit('SET_ADHESION', {
      //   id: null,
      //   rappel: 1,
      //   cotisation: null,
      //   lettre_mission: 0,
      //   xls: 1,
      //   valide: 1,
      //   validation: 1,
      //   adherent_checked: false,
      //   representant_checked: false,
      //   sidepockets_checked: false,
      //   notes: null,
      // })

      // commit('SET_ADHERENT', {
      //   id: null,
      //   nationalite_id: null,
      //   societe: '',
      //   siret: '',
      //   civilite: '',
      //   nom: '',
      //   prenom: '',
      //   email: '',
      //   date_de_naissance: '',
      //   ville_naissance: '',
      //   profession: '',
      //   adresse1: '',
      //   adresse2: '',
      //   codepostal: '',
      //   ville: '',
      //   code_pays: '',
      //   pays: '',
      //   telephone: '',
      //   statut: '',
      // })

      // commit('SET_REPRESENTANT_LEGAL', {
      //   id: null,
      //   nationalite_id: null,
      //   civilite: '',
      //   nom: '',
      //   prenom: '',
      //   email: '',
      //   date_de_naissance: '',
      //   ville_naissance: '',
      //   profession: '',
      //   adresse1: '',
      //   adresse2: '',
      //   codepostal: '',
      //   ville: '',
      //   code_pays: '',
      //   pays: '',
      //   telephone: '',
      //   statut: '',
      // })
      // commit('SET_DATA', 'documents', _documents)

      // commit('DELETE_ALL_SP')

      // state.hasRepresentant = false
    },
    async setAdherentChecked({ commit }, { id, checked }) {
      await axios.patch(`adhesions/${id}/set-adherent-checked`, checked)
        .catch(e => {
          commit('SET_ADHERENT_CHECKED', !checked.checked)
          throw e
        })
    },
    async setRepresentantChecked({ commit }, { id, checked }) {
      await axios.patch(`adhesions/${id}/set-representant-checked`, checked)
        .catch(e => {
          commit('SET_REPRESENTANT_CHECKED', !checked.checked)
          throw e
        })
    },
    async updateAdherent({ state, commit }, data) {
      const { id } = state.adherent
      await axios.put(`adherent/${id}`, data)
        .then(response => {
          const adherent = response.data
          adherent.date_de_naissance = sqlDateConvert(adherent.date_de_naissance)
          commit('SET_DATA', { prop: 'adherent', values: adherent })
        })
        .catch(e => {
          throw e
        })
    },

    async saveIndivision({ commit, state }, data) {
      let ParamId = data.indivision.id
      let method = 'post'
      if (ParamId !== null) {
        method = 'put'
      } else {
        ParamId = data.indivision.adherent_id
      }
      const id = await axios[method](`indivision/${ParamId}`, data.indivision)
        .then(response => {
          const indiv = response.data
          indiv.date_de_naissance = sqlDateConvert(indiv.date_de_naissance)
          commit('UPDATE_INDIVISION', { index: data.index, values: indiv })

          return state.indivisions[data.index].id
        })
        .catch(e => {
          throw e
        })

      return id
    },

    async updateRepresentant({ commit }, { data, index }) {
      const { id } = data
      await axios.put(`representant/${id}`, data)
        .then(response => {
          const representant = response.data
          representant.date_de_naissance = sqlDateConvert(representant.date_de_naissance)
          commit('UPDATE_REPRESENTANT', { values: representant, index })
        })
        .catch(e => {
          throw e
        })
    },

    async saveRepresentant({ state, commit }, { index }) {
      state.representantLegal[index].adherent_id = state.adherent.id
      await axios.post('representant/', state.representantLegal[index])
        .then(response => {
          const representant = response.data
          representant.date_de_naissance = sqlDateConvert(representant.date_de_naissance)
          commit('UPDATE_REPRESENTANT', { values: representant, index })
        })
        .catch(e => {
          throw e
        })
    },

    async updateSidepocket({ state, commit }, { sidepocket, index }) {
      const { id } = state.sidepockets[index]
      await axios.put(`sidepockets/${id}/update`, sidepocket)
        .then(response => {
          commit('UPDATE_SP', { sidepocket: response.data, index })
        })
    },

    async addSidepocket({ commit }, { sidepocket }) {
      await axios.post('sidepockets/add', sidepocket)
        .then(response => {
          commit('ADD_SP', response.data)
        })
    },

    async deleteSidepocket({ commit, state }, index) {
      const { id } = state.sidepockets[index]
      await axios.delete(`sidepockets/${id}/delete`)
        .then(() => {
          commit('DELETE_SP', index)
        })
    },

    async deleteIndivision({ commit }, data) {
      if (data.indivision.id !== null) {
        await axios.delete(`indivision/${data.indivision.id}`)
      }
      commit('DELETE_INDIVISION', data.index)
    },

    async updateCgp({ state, commit }, bonId) {
      await axios.patch(`adhesions/${state.adhesion.id}/update-cgp`, { bon_id: bonId })
        .then(() => {
          commit('UPDATE_CGP', bonId)
        })
    },

    async updateLang({ state, commit }, lang) {
      await axios.patch(`adhesions/${state.adhesion.id}/update-lang`, { lang, locale: lang })
        .then(() => {
          commit('UPDATE_LANG', lang)
        })
    },

    async updatePi({ state }) {
      await axios.patch(`documents/${state.documents.id}/update-pi`, {
        type_pi: state.documents.type_pi,
        validite_pi: state.documents.validite_pi,
      })
    },

    async validateSp({ commit }, { index, id, val }) {
      await axios.patch(`sidepockets/${id}/validate`, { valid: val })
        .then(response => {
          commit('SET_SP_VALIDATED', { index, val: response.data.valid })
        })
    },
  },
}
