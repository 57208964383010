import { isEmpty } from './index'

export const required = value => (((value && value.length) || (typeof value === 'number')) ? true : 'Ce champ est obligatoire')

export const emailValidator = value => {
  if (isEmpty(value)) {
    return true
  }

  // eslint-disable-next-line
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  if (Array.isArray(value)) {
    return value.every(val => re.test(String(val)))
  }

  return re.test(String(value)) || 'Veuillez saisir une adresse e-mail valide'
}

export const passwordValidator = password => {
  /* eslint-disable no-useless-escape */
  const regExp = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$;%&*()]).{8,}/
  /* eslint-enable no-useless-escape */
  const validPassword = regExp.test(password)

  return (
    // eslint-disable-next-line operator-linebreak
    validPassword || 'Ce champ doit contenir au moins une majuscule, une minuscule, un caractère spécial, un chiffre et contenir au moins 8 caractères.'
  )
}

export const optionalPasswordValidator = password => {
  if (isEmpty(password)) {
    return true
  }
  /* eslint-disable no-useless-escape */
  const regExp = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$;%&*()]).{8,}/
  /* eslint-enable no-useless-escape */
  const validPassword = regExp.test(password)

  return (
    // eslint-disable-next-line operator-linebreak
    validPassword || 'Ce champ doit contenir au moins une majuscule, une minuscule, un caractère spécial, un chiffre et contenir au moins 8 caractères.'
  )
}

export const confirmedValidator = (value, target) =>
  // eslint-disable-next-line implicit-arrow-linebreak
  value === target || 'La confirmation du mot de passe ne correspond pas'

export const between = (value, min, max) => () => {
  const valueAsNumber = Number(value)

  return (Number(min) <= valueAsNumber && Number(max) >= valueAsNumber) || `Entrer un nombre entre ${min} et ${max}`
}

export const requiredWithValidator = (value, target, targetValue) => {
  if (target === targetValue) {
    return (value !== '' && value !== null) || 'Ce champ est obligatoire'
  }

  return true
}

export const dateValidator = date => {
  if (isEmpty(date)) {
    return true
  }
  const regExp = /^(\d{2})\/(\d{2})\/(\d{4})$/
  const validDate = regExp.test(date)

  return validDate || 'La date doit être au format JJ/MM/AAAA'
}
export const integerValidator = value => {
  if (isEmpty(value)) {
    return true
  }

  if (Array.isArray(value)) {
    return value.every(val => /^-?[0-9]+$/.test(String(val)))
  }

  return /^-?[0-9]+$/.test(String(value)) || 'Ce champ doit être un entier'
}

export const regexValidator = (value, regex) => {
  if (isEmpty(value)) {
    return true
  }

  let regeX = regex
  if (typeof regeX === 'string') {
    regeX = new RegExp(regeX)
  }

  if (Array.isArray(value)) {
    return value.every(val => regexValidator(val, { regeX }))
  }

  return regeX.test(String(value)) || "L'expression régulière n'est pas valide"
}

export const alphaValidator = value => {
  if (isEmpty(value)) {
    return true
  }

  // const valueAsString = String(value)

  return /^[A-Z]*$/i.test(String(value)) || 'Le champ alpha ne doit contenir que des caractères alphabétiques'
}

export const urlValidator = value => {
  if (value === undefined || value === null || value.length === 0) {
    return true
  }
  /* eslint-disable no-useless-escape */
  const re = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/

  return re.test(value) || 'URL invalide'
}

export const lengthValidator = (value, length) => {
  if (isEmpty(value)) {
    return true
  }

  return value.length === length || `Le champ doit contenir au moins ${length} caractères`
}
export const alphaDashValidator = value => {
  if (isEmpty(value)) {
    return true
  }

  const valueAsString = String(value)

  return /^[0-9A-Z_-]*$/i.test(valueAsString) || 'Tous les caractères ne sont pas valides'
}

export const moreThanZeroValidator = value => {
  if (isEmpty(value)) {
    return true
  }

  const valueAsNumber = Number(value)

  return valueAsNumber > 0 || 'La valeur du champ doit être supérieure à 0'
}
