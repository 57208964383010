export const parseDate = dates => {
  if (!dates) return null
  const [month, day, year] = dates.split('/')

  return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
}

export const sqlDateConvert = dates => {
  if (!dates) return null
  const [year, month, day] = dates.split('-')

  return `${day.padStart(2, '0')}/${month.padStart(2, '0')}/${year}`
}

export const formatDate = dates => {
  if (!dates) return null
  const [year, month, day] = dates.split('-')

  return `${day}/${month}/${year}`
}

export const getOptionsValue = (option, value, name, key = 'code') => {
  const opt = option.find(o => o[key] === value)
  if (opt === undefined) {
    return null
  }

  return opt[name]
}
