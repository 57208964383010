<template>
  <v-form
    ref="form"
    class="pb-6 pt-2"
  >
    <v-row>
      <v-col>
        <v-card
          class="pb-6"
          :class="{ valid: representantChecked }"
          outlined
        >
          <v-card-title class="d-flex justify-space-between">
            <div class="text-success">
              Représentant(s)
              <v-icon
                v-if="representantChecked"
                color="success"
              >
                {{ mdiCheckBold }}
              </v-icon>
            </div>
            <div>
              <v-switch
                v-model="representantChecked"
                :label="(representantChecked ? 'V' : 'Non v') + 'alidés'"
                :loading="loading.checked"
                :disabled="loading.checked"
                color="success"
                @click="setChecked"
              ></v-switch>
            </div>
          </v-card-title>
          <v-card-text>
            <v-row
              v-for="(representant, i) in representantLegal"
              :key="representant.id"
            >
              <v-col>
                <v-card
                  class="pb-6"
                  outlined
                >
                  <v-card-title>{{ representant.civilite }} {{ representant.nom }} {{ representant.prenom }}</v-card-title>
                  <v-card-text>
                    <v-row>
                      <v-col
                        cols="12"
                        md="2"
                      >
                        <v-select
                          v-model="representant.civilite"
                          :items="['M.', 'Mme.', 'M. & Mme.']"
                          label="Civilité"
                          :rules="[required]"
                          outlined
                          dense
                          hide-details
                        ></v-select>
                      </v-col>
                      <v-col
                        cols="12"
                        md="4"
                      >
                        <v-text-field
                          v-model="representant.nom"
                          label="Nom"
                          :rules="[required]"
                          outlined
                          dense
                          hide-details
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-text-field
                          v-model="representant.prenom"
                          label="Prénom"
                          :rules="[required]"
                          outlined
                          dense
                          hide-details
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-menu
                          ref="menuref"
                          v-model="datepicker[i]"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="representant.date_de_naissance"
                              class="hide-detail"
                              label="Date de naissance"
                              :rules="[required, dateValidator]"
                              v-bind="attrs"
                              outlined
                              dense
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="date[i]"
                            no-title
                            color="primary"
                            locale="fr-fr"
                            @input="datepicker[i] = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-text-field
                          v-model="representant.ville_naissance"
                          label="Ville de naissance"
                          outlined
                          dense
                          hide-details
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-select
                          v-model="representant.nationalite_id"
                          :items="nationalitesOptions"
                          item-text="nom"
                          item-value="id"
                          label="Nationalité"
                          outlined
                          dense
                          hide-details
                        ></v-select>
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-text-field
                          v-model="representant.profession"
                          label="Profession"
                          :rules="[required]"
                          outlined
                          dense
                          hide-details
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                      >
                        <v-text-field
                          v-model="representant.adresse1"
                          label="Numéro et libellé de voie"
                          :rules="[required]"
                          outlined
                          dense
                          hide-details
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                      >
                        <v-text-field
                          v-model="representant.adresse2"
                          label="Lieu dit ou service particulier de distribution"
                          outlined
                          dense
                          hide-details
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-text-field
                          v-model="representant.codepostal"
                          label="Code postal"
                          :rules="[required]"
                          outlined
                          dense
                          hide-details
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-text-field
                          v-model="representant.ville"
                          label="Ville"
                          :rules="[required]"
                          outlined
                          dense
                          hide-details
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-select
                          v-model="representant.code_pays"
                          :items="paysOptions"
                          item-text="pays"
                          item-value="code"
                          label="Pays"
                          :rules="[required]"
                          outlined
                          dense
                          hide-details
                        ></v-select>
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-text-field
                          v-model="representant.telephone"
                          label="Téléphone"
                          :rules="[required]"
                          outlined
                          dense
                          hide-details
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-text-field
                          v-model="representant.email"
                          label="E-mail"
                          :rules="[required,emailValidator]"
                          outlined
                          dense
                          hide-details
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="text-center">
                        <v-btn
                          color="primary"
                          :loading="loading.representant[i]"
                          @click="save(i)"
                        >
                          Enregistrer
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div class="text-center">
          <v-btn
            class="mt-5"
            outlined
            @click="addRepresentant"
          >
            Ajouter un représentant
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <div class="d-flex justify-space-between pt-7">
      <v-btn
        outlined
        @click="next(1)"
      >
        Précédent
      </v-btn>
      <v-btn
        color="primary"
        @click="next(3)"
      >
        Suivant
      </v-btn>
    </div>
    <v-snackbar
      v-model="formError"
      :timeout="5000"
      color="error"
      top
    >
      Merci de renseigner l'ensemble des champs avant de poursuivre.
    </v-snackbar>
  </v-form>
</template>

<script>
/* eslint-disable object-curly-newline */
import { dateValidator, emailValidator, required } from '@core/utils/validation'
import { mdiCalendar, mdiCheckBold } from '@mdi/js'
import { computed, defineComponent, onMounted, ref, watch } from '@vue/composition-api'
import { useState } from 'vuex-composition-helpers'
import store from '@/store/adhesion'
import { nationalitesOptions, paysOptions } from '@/data'

// import { useVuelidate } from '@vuelidate/core'
import { formatDate, parseDate } from './adherents'

/* eslint-enable object-curly-newline */

export default defineComponent({
  props: {
    adhesionId: {
      type: Number,
      required: true,
    },
  },
  setup(props, { emit }) {
    const formError = ref(false)

    const date = ref([])
    const datepicker = ref([])

    const { representantLegal } = useState('adherent', ['representantLegal'])

    const form = ref(null)
    const loading = ref({
      checked: false,
      representant: [],
    })

    const representantChecked = computed({
      get() {
        return store.state.adherent.adhesion.representant_checked
      },
      set(val) {
        store.commit('adherent/SET_REPRESENTANT_CHECKED', val)
      },
    })

    // const date = computed(() => {
    //   // eslint-disable-next-line no-underscore-dangle
    //   const _date = []
    //   // eslint-disable-next-line no-plusplus
    //   representantLegal.value.forEach(representant => {
    //     _date[representant.id] = new Date().toISOString().substr(0, 10)
    //   })

    //   return _date
    // })

    // const datepicker = computed(() => {
    //   const picker = []
    //   // eslint-disable-next-line no-plusplus
    //   representantLegal.value.forEach(representant => {
    //     picker[representant.id] = false
    //   })

    //   return picker
    // })

    const setChecked = async () => {
      loading.value.checked = true
      try {
        await store.dispatch('adherent/setRepresentantChecked', { id: props.adhesionId, checked: { checked: representantChecked.value } })
      } catch (e) {
        console.error('error', e)
        emit('alert', { type: 'error', msg: e })
      } finally {
        loading.value.checked = false
      }
    }

    const next = step => {
      emit('next-step', step)
    }

    // const save = step => {
    //   formError.value = false
    //   if (!form.value.validate()) {
    //     formError.value = true
    //   } else {
    //     representantLegal.value.pays = getOptionsValue(paysOptions, representantLegal.value.code_pays, 'pays')
    //     store.commit('adherent/SET_DATA', { prop: 'representantLegal', values: representantLegal.value })
    //     next(step)
    //   }
    // }

    const resetData = () => {
      // representantLegal.value = store.getters['adherent/getRepresentantLegal']
      form.value.reset()
    }

    const save = async i => {
      loading.value.representant = true
      let action = 'updateRepresentant'
      let successMsg = 'Modification du représentant légal effectuée !'
      let data = { data: representantLegal.value[i], index: i }
      if (representantLegal.value[i].id === null) {
        action = 'saveRepresentant'
        successMsg = 'Représentant légal ajouté !'
        data = { index: i }
      }
      try {
        await store.dispatch(`adherent/${action}`, data)
        emit('alert', { type: 'success', msg: successMsg })
      } catch (e) {
        emit('alert', { type: 'error', msg: 'Enregistrement impossible, vérifiez que tous les champs on bien été renseignés.' })
      } finally {
        loading.value.representant = false
      }
    }

    const addRepresentant = () => {
      store.commit('adherent/ADD_REPRESENTANT')
    }

    watch(representantLegal, () => {
      representantLegal.value.forEach((i, representant) => {
        if (representant.date_de_naissance !== undefined) {
          const dateNaissance = representant.date_de_naissance.split('/')
          date.value[i] = `${dateNaissance[2]}-${dateNaissance[1]}-${dateNaissance[0]}`
        }
      })
    }, { deep: true })

    onMounted(() => {
      representantLegal.value.forEach((representant, i) => {
        date.value[i] = new Date().toISOString().substr(0, 10)
        datepicker.value[i] = false
        loading.value.representant[i] = false
      })
    })

    watch(date, () => {
      representantLegal.value.forEach((representant, i) => {
        // eslint-disable-next-line no-param-reassign
        representant.date_de_naissance = formatDate(date.value[i])
      })
    })

    return {
      representantLegal,
      datepicker,
      form,
      mdiCalendar,
      mdiCheckBold,
      date,
      formatDate,
      parseDate,
      paysOptions,
      nationalitesOptions,
      next,
      save,
      formError,
      resetData,
      representantChecked,
      loading,
      setChecked,
      addRepresentant,

      // Validation
      required,
      emailValidator,
      dateValidator,
    }
  },
})
</script>

<style>
.hide-detail .v-input__control .v-text-field__details {
  display: none;
}
</style>
