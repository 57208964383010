<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        md="6"
      >
        <div class="d-flex align-center mb-4">
          <div class="text-2xl mr-5">
            Modifiation de {{ name }}
          </div>
          <v-speed-dial
            v-model="validationMenu"
            direction="bottom"
            transition="slide-y-transition"
          >
            <template v-slot:activator>
              <v-btn
                :color="colors[validation]"
                x-small
                fab
                :loading="validationLoading"
              >
                <v-icon v-if="validation === 1">
                  {{ mdiHelpCircleOutline }}
                </v-icon>
                <v-icon v-if="validation === 2">
                  {{ mdiProgressPencil }}
                </v-icon>
                <v-icon v-if="validation === 3">
                  {{ mdiCheckCircleOutline }}
                </v-icon>
                <v-icon v-if="validation === 4">
                  {{ mdiAlertCircleOutline }}
                </v-icon>
                <v-icon v-if="validation === 5">
                  {{ mdiCheckDecagramOutline }}
                </v-icon>
              </v-btn>
            </template>
            <v-btn
              v-if="validation !== 1"
              fab
              dark
              x-small
              color="secondary"
              @click="setValidation(1)"
            >
              <v-icon>{{ mdiHelpCircleOutline }}</v-icon>
            </v-btn>
            <v-btn
              v-if="validation !== 2"
              fab
              dark
              x-small
              color="warning"
              @click="setValidation(2)"
            >
              <v-icon>{{ mdiProgressPencil }}</v-icon>
            </v-btn>
            <v-btn
              v-if="validation !== 3"
              fab
              dark
              x-small
              color="info"
              @click="setValidation(3)"
            >
              <v-icon>{{ mdiCheckCircleOutline }}</v-icon>
            </v-btn>
            <v-btn
              v-if="(validation !== 5)"
              fab
              dark
              x-small
              color="success"
              @click="setValidation(5)"
            >
              <v-icon>{{ mdiCheckDecagramOutline }}</v-icon>
            </v-btn>
            <v-btn
              v-if="validation !== 4"
              fab
              dark
              x-small
              color="error"
              @click="setValidation(4)"
            >
              <v-icon>{{ mdiAlertCircleOutline }}</v-icon>
            </v-btn>
          </v-speed-dial>
          <div class="ml-2 text-uppercase">
            {{ statut[validation] }}
          </div>
        </div>
      </v-col>
      <v-col
        class="d-flex justify-end"
        cols="12"
        md="6"
      >
        <v-btn
          color="primary"
          class="me-3 mb-4"
          :to="{ name: 'liste-adherents' }"
          link
        >
          <span>Retour à la liste</span>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card class="mb-5">
          <v-card-actions>
            <v-btn
              :color="notes === null ? 'lighten-2' : 'error'"
              text
              @click="showComment = !showComment"
            >
              Commentaires {{ notes === null ? '(aucun)' : '' }}
              <v-icon
                v-if="notes !== null"
                class="ml-2"
                color="error"
              >
                {{ mdiArrowDownThick }}
              </v-icon>
            </v-btn>

            <v-spacer></v-spacer>

            <v-btn
              icon
              @click="showComment = !showComment"
            >
              <v-icon>{{ showComment ? mdiChevronUp : mdiChevronDown }}</v-icon>
            </v-btn>
          </v-card-actions>
          <v-expand-transition>
            <div v-show="showComment">
              <v-divider></v-divider>
              <v-card-text>
                <v-textarea
                  v-model="notes"
                  filled
                ></v-textarea>
                <div class="text-center">
                  <v-btn
                    color="primary"
                    outlined
                    @click="setNotes"
                  >
                    Enregistrer
                  </v-btn>
                </div>
              </v-card-text>
            </div>
          </v-expand-transition>
        </v-card>
      </v-col>
    </v-row>

    <v-stepper
      v-model="activeStep"
      class="custom-header"
      non-linear
    >
      <v-overlay
        absolute
        :value="loading"
      >
        <v-progress-circular
          indeterminate
          size="64"
        ></v-progress-circular>
      </v-overlay>
      <!-- Header -->
      <v-stepper-header>
        <!-- Header: Step 1 -->
        <v-stepper-step
          :complete="activeStep > 1"
          step="1"
          editable
        >
          <div class="d-flex align-center">
            <span class="text--primary text-4xl font-weight-bold me-3">01</span>
            <div class="d-flex flex-column text-left">
              <span class="text--primary text-sm font-weight-semibold">Détails adhérents</span>
              <span class="text--secondary text-xs">Informations sur l'adhérent</span>
            </div>
          </div>
        </v-stepper-step>

        <v-divider v-if="representant"></v-divider>

        <v-stepper-step
          v-if="representant"
          :complete="activeStep > 2"
          step="2"
          editable
        >
          <div class="d-flex align-center">
            <span class="text--primary text-4xl font-weight-bold me-3">02</span>
            <div class="d-flex flex-column text-left">
              <span class="text--primary text-sm font-weight-semibold">Representant(s)</span>
              <span class="text--secondary text-xs">Mineur ou entreprise</span>
            </div>
          </div>
        </v-stepper-step>

        <v-divider></v-divider>

        <!-- Header: Step 2 -->
        <v-stepper-step
          :complete="activeStep > (2 + stepSup)"
          :step="2 + stepSup"
          editable
        >
          <div class="d-flex align-center">
            <span class="text--primary text-4xl font-weight-bold me-3">0{{ 2 + stepSup }}</span>
            <div class="d-flex flex-column text-left">
              <span class="text--primary text-sm font-weight-semibold">Side-pocket</span>
              <span class="text--secondary text-xs">Fonds souscrits</span>
            </div>
          </div>
        </v-stepper-step>
      </v-stepper-header>

      <!-- Stepper Items -->
      <v-stepper-items>
        <!-- Stepper Content: Step 1 -->
        <v-stepper-content step="1">
          <informations-form
            ref="informationscomp"
            :adhesion-id="id"
            @next-step="nextStep"
            @representant-legal="representantLegal"
            @alert="snackbarAlert"
          ></informations-form>
        </v-stepper-content>

        <!-- Stepper Content: Step 2 -->
        <v-stepper-content
          v-if="representant"
          step="2"
        >
          <representant-form
            ref="representantcomp"
            :adhesion-id="id"
            @next-step="nextStep"
            @alert="snackbarAlert"
          ></representant-form>
        </v-stepper-content>

        <!-- Stepper Content: Step 2 -->
        <v-stepper-content :step="2 + stepSup">
          <side-pocket-form
            v-if="!loading"
            ref="spcomp"
            :step-sup="stepSup"
            @next-step="nextStep"
            @alert="snackbarAlert"
          ></side-pocket-form>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
    <v-snackbar
      v-model="snackbarVisible"
      :timeout="4000"
      :color="snackbarColor"
      top
    >
      {{ snackbarMsg }}
    </v-snackbar>
  </div>
</template>

<script>
import axios from '@axios'
import {
  mdiAlertCircleOutline, mdiArrowDownThick, mdiCheckCircleOutline, mdiCheckDecagramOutline, mdiChevronDown, mdiChevronUp, mdiHelpCircleOutline, mdiPencil, mdiProgressPencil,
} from '@mdi/js'
import themeConfig from '@themeConfig'
import {
  computed, onBeforeMount, onMounted, ref,
} from '@vue/composition-api'
import store from '@/store/adhesion'
import router from '@/router'
import informationsForm from './InformationsForm.vue'
import representantForm from './RepresentantForm.vue'
import sidePocketForm from './SidePocketForm.vue'

export default {
  components: {
    informationsForm,
    representantForm,
    sidePocketForm,
  },
  setup() {
    const activeStep = ref(1)
    const stepSup = ref(0)

    // const representant = ref(false)
    const informationscomp = ref(null)
    const representantcomp = ref(null)
    const spcomp = ref(null)

    // const validation = ref(store.state.adherent.adhesion.validation)
    const validation = computed({
      get() {
        return store.state.adherent.adhesion.validation
      },
      set(val) {
        store.commit('adherent/SET_VALIDATION', val)
      },
    })

    const notes = computed({
      get() {
        return store.state.adherent.adhesion.notes || null
      },
      set(val) {
        store.commit('adherent/SET_NOTES', val)
      },
    })

    const representant = computed({
      get() {
        const { hasRepresentant } = store.state.adherent
        stepSup.value = hasRepresentant ? 1 : 0

        return hasRepresentant
      },
      set(val) {
        store.commit('adherent/SET_HAS_REPRESENTANT', val)
      },
    })

    const showComment = ref(false)

    const statut = [
      '',
      'NON VALIDÉ',
      'EN ATTENTE',
      'VALIDÉ',
      'REFUSÉ',
      'LDM SIGNÉE',
    ]
    const colors = ['primary', 'secondary', 'warning', 'info', 'error', 'success']
    const validationMenu = ref(false)
    const validationLoading = ref(false)
    const notesLoading = ref(false)
    const snackbarVisible = ref(false)
    const snackbarColor = ref(null)
    const snackbarMsg = ref('')

    const name = computed(() => {
      if (store.state.adherent.adherent.statut === 'particulier') {
        return `${store.state.adherent.adherent.nom} ${store.state.adherent.adherent.prenom}`
      }

      return store.state.adherent.adherent.societe
    })

    const id = +router.currentRoute.params.id

    const loading = ref(false)

    const snackbarAlert = ({ type, msg }) => {
      snackbarVisible.value = true
      snackbarColor.value = type
      snackbarMsg.value = msg
    }

    const setValidation = async val => {
      validationLoading.value = true

      await axios.post(`${themeConfig.app.baseURL}api/adhesions/update/${id}/validation/${val}`)
        .then(response => {
          validation.value = response.data.validation
        })
        .catch(e => {
          snackbarAlert({ type: 'error', msg: e })
        })
        .finally(() => {
          validationLoading.value = false
        })
    }

    const setNotes = async () => {
      notesLoading.value = true
      await axios.post(`${themeConfig.app.baseURL}api/adhesions/update/${id}/notes`, { notes: store.state.adherent.adhesion.notes })
        .then(() => {
          snackbarAlert({ type: 'success', msg: 'Commentaire mis à jour !' })
        })
        .catch(e => {
          snackbarAlert({ type: 'error', msg: e })
        })
        .finally(() => {
          notesLoading.value = false
        })
    }

    const nextStep = step => {
      activeStep.value = step
    }

    const resetData = () => {
      informationscomp.value.resetData()
      spcomp.value.resetData()
      if (representant.value) {
        representantcomp.value.resetData()
      }
    }

    const representantLegal = active => {
      stepSup.value = active ? 1 : 0
      representant.value = active
    }

    onBeforeMount(() => {
      // store.dispatch('adherent/resetAll')

      store.commit('adherent/RESET_ALL')
    })

    onMounted(async () => {
      loading.value = true
      await store.dispatch('adherent/fetchData', id)
      loading.value = false
    })

    // watch(representant, () => {
    //   stepSup.value = representant ? 1 : 0
    // })

    return {
      name,
      representant,
      stepSup,
      activeStep,
      nextStep,
      representantLegal,
      informationscomp,
      representantcomp,
      spcomp,
      resetData,
      loading,
      validation,
      colors,
      validationLoading,
      notesLoading,
      validationMenu,
      snackbarVisible,
      snackbarColor,
      snackbarMsg,
      setValidation,
      setNotes,
      snackbarAlert,
      mdiAlertCircleOutline,
      mdiCheckDecagramOutline,
      mdiCheckCircleOutline,
      mdiHelpCircleOutline,
      mdiProgressPencil,
      mdiPencil,
      mdiChevronUp,
      mdiChevronDown,
      mdiArrowDownThick,
      statut,
      id,
      showComment,
      notes,
    }
  },
}
</script>

<style lang="scss" scoped>
</style>
