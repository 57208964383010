// eslint-disable-next-line object-curly-newline
export const adhesion = {
  rappel: 1,
  cotisation: null,
  valide: 1,
  adherent: null,
}

export const adherent = {
  nationalite_id: null,
  societe: '',
  siret: '',
  civilite: '',
  nom: 'test',
  prenom: '',
  email: '',
  date_de_naissance: '',
  ville_naissance: '',
  profession: '',
  adresse1: '',
  adresse2: '',
  codepostal: '',
  ville: '',
  pays: '',
  code_pays: '',
  telephone: '',
  statut: '',
  sidepockets: null,
}

export const sidepockets = []

export const assureurs = [
  'ACMN Vie',
  'La Mondiale France',
  'Agéas',
  'AGPM Vie',
  'Allianz Vie',
  'Antarius',
  'Apicil',
  'AVIVA',
  'AXA',
  'BNP Paribas Cardif',
  'BPCE Vie',
  'Caisse d\'épargne',
  'Capma & Capmi',
  'Carac',
  'Cardif',
  'CNP Assurance',
  'Crédit Agricole',
  'FINANCIERE DEGESUD',
  'GARANCE',
  'Generali',
  'GMF',
  'groupama gan vie',
  'Harmonie Mutuelle',
  'La France Mutualiste',
  'Le Conservateur',
  'MAAF VIE',
  'MACSF',
  'Matmut',
  'MGEN',
  'MMA',
  'Mutavie',
  'Mutuelle d\'Ivry - La Fraternelle',
  'Natixis Assurances',
  'Neuflize Vie',
  'Oradea Vie',
  'Parnasse-MAIF',
  'Predica',
  'PREPAR-VIE',
  'SMAvie BTP',
  'Sogécap',
  'Sogelife Lux',
  'Spirica',
  'Suravenir',
  'Swiss Life',
  'Cardif Lux',
  'Baloise Vie',
  'Allianz Lux',
  'One Life',
  'La Mondiale Lux',
  'Wealins',
  'Lombard International',
  'Generali Lux',
  'CNP Lux',
  'Vitis Life',
]
