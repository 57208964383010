// eslint-disable-next-line object-curly-newline
export const adhesion = {
  id: null,
  bon_id: null,
  rappel: 1,
  cotisation: null,
  lettre_mission: 0,
  xls: 1,
  ldm: 0,
  valide: 1,
  validation: 1,
  adherent_checked: false,
  representant_checked: false,
  sidepockets_checked: false,
  notes: null,
}

export const adherent = {
  id: null,
  nationalite_id: null,
  societe: '',
  siret: '',
  forme_juridique: '',
  civilite: '',
  nom: '',
  prenom: '',
  email: '',
  date_de_naissance: '',
  ville_naissance: '',
  profession: '',
  adresse1: '',
  adresse2: '',
  codepostal: '',
  ville: '',
  code_pays: '',
  pays: '',
  telephone: '',
  statut: '',
}

export const indivision = {
  id: null,
  adherent_id: null,
  nationalite_id: null,
  civilite: '',
  nom: '',
  prenom: '',
  email: '',
  date_de_naissance: '',
  ville_naissance: '',
  profession: '',
  adresse1: '',
  adresse2: '',
  codepostal: '',
  ville: '',
  code_pays: '',
  pays: '',
  telephone: '',
}

export const representantLegal = {
  id: null,
  nationalite_id: null,
  civilite: '',
  nom: '',
  prenom: '',
  email: '',
  date_de_naissance: '',
  ville_naissance: '',
  profession: '',
  adresse1: '',
  adresse2: '',
  codepostal: '',
  ville: '',
  code_pays: '',
  pays: '',
  telephone: '',
  statut: '',
}

export const documents = {
  id: null,
  adherent_id: null,
  type_pi: '',
  validite_pi: '',
  fichier_pi: [],
  fichier_releve: [],
  fichier_rib: [],
  fichier_kbis: [],
}

export const sidepockets = []
